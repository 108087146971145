import { Box, makeStyles, styled, Typography } from '@material-ui/core';

export const LinkText: any = styled(Typography)(({ theme }) => ({
  fontFamily: 'Barlow',
  fontSize: '16px',
  fontWeight: 600,
  color: '#676767',
  padding: 0,
  opacity: 1,
  '&:hover': {
    textDecoration: 'underline',
    color: '#0080ed',
    cursor: 'pointer',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
  },

  '& .jss7': {
    padding: 0,
  },
}));

export const useStyles = makeStyles(theme => ({
  linkActived: {
    fontFamily: 'Barlow',
    fontSize: '16px',
    fontWeight: 600,
    color: '#0080ed',
    cursor: 'text',
    padding: 0,
    '&:hover': {
      textDecoration: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
}));

export const BoxBread: any = styled(Box)(({ theme }) => ({
  margin: '25px 0 24px 56px',
  [theme.breakpoints.down('sm')]: {
    margin: '0px 0 16px 20px',
    paddingTop: '10px',
    fontSize: '18px',
  },
  [theme.breakpoints.between(1340, 3000)]: {
    margin: '25px 0 24px 0px',
  },
  [theme.breakpoints.between(960, 1279)]: {
    margin: '25px 0 24px 20px',
  },
}));
