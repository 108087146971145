import {
  Box,
  CircularProgress as MaterialCircularProgress,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import { Label, useStyles } from './styles';

interface IProgress {
  value: number;
  showLabel: boolean;
}
const CircularProgress: React.FC<IProgress> = ({ value, showLabel }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const ContainerBox: any = Box;
  return (
    <ContainerBox
      mr={4}
      position="relative"
      display="inline-flex"
      className="progress"
    >
      <MaterialCircularProgress
        variant="determinate"
        value={100}
        thickness={6}
        size={isMobile ? 60 : 78}
        className={classes.bottom}
      />
      <MaterialCircularProgress
        variant="determinate"
        value={value}
        color="primary"
        thickness={6}
        size={isMobile ? 60 : 78}
        className={classes.top}
      />
      {showLabel && (
        <ContainerBox
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgcolor="white"
          borderRadius="100%"
        >
          <Label variant="caption">
            {`${Math.round(value)}`}
            <span>%</span>
          </Label>
        </ContainerBox>
      )}
    </ContainerBox>
  );
};

export default CircularProgress;
