import { IStudentCourseLesson } from '@utils/interfaces/studentCourseLesson';
import * as courseService from '@services/content/courseByStudent';
import { ILessonItemList } from '@utils/interfaces/modulesWithLessons';
import * as studentCourseLesson from '@services/content/studentCourseLesson';
import router from 'next/router';
import { CourseUtils, ICourseUtils } from './utils';
import { ICourseFunctions, ICourseState } from './types';

export class CourseActions {
  private courseUtils: ICourseUtils;

  constructor() {
    this.courseUtils = new CourseUtils();
  }

  public handleCurrentLessonBySlug = ({
    data,
    state,
    onSuccess,
  }: Pick<
    ICourseFunctions<
      { lessonSlug: string; courseLessonId?: string },
      Pick<ICourseState, 'currentLesson'>,
      Pick<ICourseState, 'course' | 'modules'>
    >,
    'data' | 'onSuccess' | 'state'
  >): void => {
    const { modules, course } = state;
    const allLessons = this.courseUtils.getAllLessons(modules);
    let newCurrentLesson = allLessons.find(
      lesson =>
        lesson.lesson.slug === data.lessonSlug &&
        (!data?.courseLessonId || data?.courseLessonId === lesson.id),
    );
    if (!newCurrentLesson) newCurrentLesson = modules[0].courseLessons[0];

    this.handleCurrentLesson({
      data: {
        course: course.id,
        hasCompleted: newCurrentLesson.hasCompleted,
        lastAccessLesson: newCurrentLesson.id,
      },
      onSuccess,
      state,
    });
  };

  public async handleVideoTime({
    data,
    state,
    onSuccess,
  }: Pick<
    ICourseFunctions<
      courseService.IUpdateStudentCourseLesson,
      Pick<ICourseState, 'modules'>,
      Pick<ICourseState, 'modules'>
    >,
    'data' | 'state' | 'onSuccess'
  >): Promise<void> {
    try {
      const { modules } = state;
      const { assistedTime, hasCompleted } = data;
      const { lessonIndex, moduleIndex } =
        this.courseUtils.getModuleAndLessonIndexByCourseLessonId(
          data.courseLesson,
          modules,
        );
      const currentLesson: ILessonItemList = {
        ...modules[moduleIndex].courseLessons[lessonIndex],
        assistedTime,
        hasCompleted,
      };

      modules[moduleIndex].courseLessons[lessonIndex] = currentLesson;
      await courseService.updateStudentCourseLesson(data);
      onSuccess({
        modules: [...modules],
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Error handleVideoTime', err);
    }
  }

  public async handleCheckLesson({
    data,
    onSuccess,
    state,
  }: Pick<
    ICourseFunctions<
      { lesson: string; hasCompleted: boolean; numberPagePdf?: number },
      Pick<ICourseState, 'modules' | 'course'>,
      Pick<ICourseState, 'modules' | 'course'>
    >,
    'data' | 'onSuccess' | 'state'
  >): Promise<void> {
    try {
      const { modules } = state;
      const { hasCompleted, numberPagePdf } = data;
      const { lessonIndex, moduleIndex } =
        this.courseUtils.getModuleAndLessonIndexByCourseLessonId(
          data.lesson,
          modules,
        );

      const currentLesson: ILessonItemList = {
        ...modules[moduleIndex].courseLessons[lessonIndex],
        numberPagePdf,
        hasCompleted,
      };

      modules[moduleIndex].courseLessons[lessonIndex] = currentLesson;

      await courseService.updateStudentCourseLesson({
        assistedTime: currentLesson.assistedTime,
        courseLesson: currentLesson.id,
        numberPagePdf,
        hasCompleted,
      });

      const progress = this.courseUtils.calcProgress(modules);
      onSuccess({
        modules: [...modules],
        course: {
          ...state.course,
          progress,
        },
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Error handleCheckLesson', err);
    }
  }

  public async handleCurrentLesson({
    data,
    state,
    onSuccess,
  }: Pick<
    ICourseFunctions<
      courseService.IUpdateStudentCourse,
      Pick<ICourseState, 'currentLesson'>,
      Pick<ICourseState, 'modules'>
    >,
    'data' | 'onSuccess' | 'state'
  >): Promise<void> {
    try {
      const { modules } = state;
      const { lessonIndex, moduleIndex } =
        this.courseUtils.getModuleAndLessonIndexByCourseLessonId(
          data.lastAccessLesson,
          modules,
        );

      const currentLesson = modules[moduleIndex].courseLessons[lessonIndex];
      onSuccess({
        currentLesson,
      });
      await courseService.updateStudentCourse({
        ...data,
        lastAccessLesson: currentLesson.lesson.id,
        lastAccessCourseLesson: currentLesson.id,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Error handleCurrentLesson', err);
    }
  }

  public async getAllLessonTypeLiveZoom(data: {
    courseId: string;
    studentId: string;
  }): Promise<IStudentCourseLesson[]> {
    try {
      const { courseId, studentId } = data;
      const response = await studentCourseLesson.findAll(courseId);
      const liveZoomLessons = response.rows.filter(row => {
        if (
          row.courseLesson?.lesson.type === 'liveZoom' &&
          row.student.id === studentId
        ) {
          return true;
        }
        return false;
      });
      return liveZoomLessons;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      throw err;
    }
  }

  public async goNextLesson({
    state,
    onSuccess,
  }: Pick<
    ICourseFunctions<
      null,
      Pick<ICourseState, 'currentLesson' | 'modules' | 'course'>,
      Pick<ICourseState, 'currentLesson' | 'modules' | 'course'>
    >,
    'onSuccess' | 'state'
  >): Promise<void> {
    try {
      const { modules, currentLesson } = state;
      const allLessons = this.courseUtils.getAllLessons(modules);

      const { lessonIndex, moduleIndex } =
        this.courseUtils.getModuleAndLessonIndexByCourseLessonId(
          currentLesson.id,
          modules,
        );

      modules[moduleIndex].courseLessons[lessonIndex] = {
        ...currentLesson,
        hasCompleted: true,
      };

      const prevIndex = allLessons.findIndex(
        lesson => lesson.id === currentLesson.id,
      );
      const nextIndex = prevIndex + 1 < allLessons.length ? prevIndex + 1 : 0;

      const progress = this.courseUtils.calcProgress(modules);

      onSuccess({
        currentLesson: allLessons[nextIndex],
        modules: [...modules],
        course: {
          ...state.course,
          progress,
        },
      });

      if (router.route !== '/curso/[slug]') {
        router.push(
          `/curso/${state.course.slug}/aulas/${allLessons[nextIndex].lesson.slug}`,
        );
      }
      await courseService.updateStudentCourseLesson({
        hasCompleted: true,
        assistedTime: allLessons[prevIndex].assistedTime,
        courseLesson: allLessons[prevIndex].id,
      });

      await courseService.updateStudentCourse({
        course: state.course.id,
        hasCompleted: allLessons[nextIndex].hasCompleted,
        lastAccessLesson: allLessons[nextIndex].lesson.id,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Error goNextLesson', err);
    }
  }

  public async newGoNextLesson({
    state,
    onSuccess,
  }: Pick<
    ICourseFunctions<
      null,
      Pick<ICourseState, 'currentLesson' | 'modules' | 'course'>,
      Pick<ICourseState, 'currentLesson' | 'modules' | 'course'>
    >,
    'onSuccess' | 'state'
  >): Promise<void> {
    try {
      const { modules, currentLesson } = state;
      const allLessons = this.courseUtils.getAllLessons(modules);

      const { lessonIndex, moduleIndex } =
        this.courseUtils.getModuleAndLessonIndexByCourseLessonId(
          currentLesson.id,
          modules,
        );

      modules[moduleIndex].courseLessons[lessonIndex] = {
        ...currentLesson,
      };

      const prevIndex = allLessons.findIndex(
        lesson => lesson.id === currentLesson.id,
      );
      const nextIndex = prevIndex + 1 < allLessons.length ? prevIndex + 1 : 0;

      const progress = this.courseUtils.calcProgress(modules);

      onSuccess({
        currentLesson: allLessons[nextIndex],
        modules: [...modules],
        course: {
          ...state.course,
          progress,
        },
      });

      if (router.route !== '/curso/[slug]') {
        router.push(
          `/curso/${state.course.slug}/aulas/${allLessons[nextIndex].lesson.slug}`,
        );
      }
      await courseService.updateStudentCourseLesson({
        assistedTime: allLessons[prevIndex].assistedTime,
        courseLesson: allLessons[prevIndex].id,
      });

      await courseService.updateStudentCourse({
        course: state.course.id,
        hasCompleted: allLessons[nextIndex].hasCompleted,
        lastAccessLesson: allLessons[nextIndex].lesson.id,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Error NewGoNextLesson', err);
    }
  }

  public async updateAdditionalInformations({
    data,
    state,
    onSuccess,
  }: Pick<
    ICourseFunctions<
      courseService.IUpdateStudentCourseAdditionalInformations,
      Pick<ICourseState, 'course'>,
      Pick<ICourseState, 'course'>
    >,
    'data' | 'state' | 'onSuccess'
  >): Promise<void> {
    await courseService.updateStudentCourseAdditionalInformations(data);
    onSuccess({
      course: {
        ...state.course,
        studentAdditionalInformations: data.additionalInformations,
      },
    });
  }

  public async getCourse({
    onError,
    onLoad,
    onSuccess,
    data,
  }: Pick<
    ICourseFunctions<
      courseService.IGetCourseByStudentParams,
      Pick<ICourseState, 'course' | 'modules' | 'currentLesson'>,
      ICourseState
    >,
    'data' | 'onSuccess' | 'onError' | 'onLoad'
  >): Promise<void> {
    try {
      onLoad(true);
      const { data: response } = await courseService.getCourseByStudent(data);
      const { course, modules } = response;
      let currentLesson;
      if (course.lastAccessLessonId) {
        const { lessonIndex, moduleIndex } =
          this.courseUtils.getModuleAndLessonIndexByLessonId(
            course.lastAccessLessonId,
            modules,
          );
        currentLesson = modules[moduleIndex]?.courseLessons[lessonIndex];
      }

      if (!currentLesson) currentLesson = modules[0]?.courseLessons[0];

      onSuccess({
        course,
        currentLesson,
        modules,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      router.push(`/`);
      onError(
        'Ocorreu um erro ao tentar obter as informações do curso. Tente Novamente',
      );
    } finally {
      onLoad(false);
    }
  }

  public async getAllLessonTypeQuiz(data: {
    courseId: string;
    studentId: string;
  }): Promise<IStudentCourseLesson[]> {
    try {
      const { courseId, studentId } = data;
      const response = await studentCourseLesson.findAll(courseId);
      const quizLessons = response.rows.filter(row => {
        if (
          row.courseLesson?.lesson.type === 'quiz' &&
          row.student.id === studentId &&
          row.quizScore !== undefined
        ) {
          return true;
        }
        return false;
      });
      return quizLessons;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      throw err;
    }
  }

  public async getAllLessonsQuiz(data: {
    courseId: string;
    studentId: string;
    courseSlug: string;
  }): Promise<IStudentCourseLesson[]> {
    try {
      const { courseId, studentId, courseSlug } = data;
      const response = await studentCourseLesson.findAll(courseId);
      const answeredQuizLessons = response.rows.filter(row => {
        if (
          row.courseLesson?.lesson.type === 'quiz' &&
          row.student.id === studentId
        ) {
          return true;
        }
        return false;
      });
      const responses = await courseService.getCourseByStudent({ courseSlug });
      const allQuizLessons = responses.data.modules
        .map(currentModule => {
          return currentModule.courseLessons;
        })
        .flat()
        .filter(currentCourseLesson => {
          return currentCourseLesson.lesson.type === 'quiz';
        });
      return allQuizLessons.map((quizLesson: any) => {
        const currentAnsweredQuizLesson = answeredQuizLessons.find(
          answeredQuizLesson => {
            return answeredQuizLesson.courseLesson.id === quizLesson.id;
          },
        );
        if (currentAnsweredQuizLesson) {
          return {
            ...quizLesson,
            ...currentAnsweredQuizLesson,
          };
        }
        return {
          hasCompleted: false,
          _id: '',
          assistedTime: 0,
          student: null,
          courseLesson: quizLesson,
          createdBy: '',
          updatedBy: '',
          createdAt: '',
          updatedAt: '',
          __v: 0,
          id: '',
          hasApproved: false,
          quizScore: undefined,
          live: null,
        };
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      throw err;
    }
  }
}
