import NotFoundAnimation from '@assets/animations/notFound.json';
import dynamic from 'next/dynamic';
import { ContentBox, ParagraphNotFound, TitleNotFound } from './styles';

const Lottie: any = dynamic(
  () => import('lottie-react').then(module => module.default),
  { ssr: false },
);

interface MenuNotFoundProps {
  title?: string;
  paragraph?: string;
  isMedium?: boolean;
}

const MenuNotFound: React.FC<MenuNotFoundProps> = ({
  title,
  paragraph,
  isMedium,
}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: NotFoundAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <>
      <ContentBox isMedium={isMedium} />
      <Lottie
        {...defaultOptions}
        style={{
          height: 174,
          width: 174,
          flex: 1,
          alignSelf: 'center',
        }}
      />

      <TitleNotFound isMedium={isMedium}>{title}</TitleNotFound>
      <ParagraphNotFound>{paragraph}</ParagraphNotFound>
    </>
  );
};

export default MenuNotFound;
