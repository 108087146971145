import { useRouter } from 'next/router';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import { useSnackbar } from '@hooks/snackbar';
import { useUser } from '@hooks/user';
import {
  IGetCourseByStudentParams,
  IUpdateStudentCourse,
  IUpdateStudentCourseAdditionalInformations,
  IUpdateStudentCourseLesson,
} from '@services/content/courseByStudent';
import { ICourseState, ICourseContext } from './types';
import { CourseActions } from './actions';
import { CourseUtils } from './utils';

export const CourseContext: any = createContext({} as ICourseContext);

const courseActions = new CourseActions();
const courseUtils = new CourseUtils();

const CourseProvider: React.FC = ({ children }) => {
  const { user } = useUser();
  const { query } = useRouter();
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState<ICourseState>({} as ICourseState);
  const { course, modules, currentLesson } = state;

  const onError = useCallback(
    (message: string) =>
      showSnackbar({
        message,
        type: 'info',
        duration: 50000,
      }),
    [showSnackbar],
  );

  const onLoad = useCallback(load => setLoading(load), []);

  const getCourse = useCallback(
    async (data: IGetCourseByStudentParams) => {
      await courseActions.getCourse({
        onError,
        onLoad,
        data,
        onSuccess: newState =>
          setState(prevState => ({ ...prevState, ...newState })),
      });
    },
    [onError, onLoad],
  );

  const handleCurrentLesson = useCallback(
    async (data: IUpdateStudentCourse) => {
      await courseActions.handleCurrentLesson({
        data,
        onSuccess: newState =>
          setState(prevState => ({ ...prevState, ...newState })),
        state: {
          modules,
        },
      });
    },
    [modules],
  );

  const handleCheckLesson = useCallback(
    async (data: {
      lesson: string;
      hasCompleted: boolean;
      numberPagePdf?: number;
    }) => {
      await courseActions.handleCheckLesson({
        data,
        onSuccess: newState =>
          setState(prevState => ({ ...prevState, ...newState })),
        state: {
          modules,
          course,
        },
      });
    },
    [course, modules],
  );

  const handleVideoTime = useCallback(
    async (data: IUpdateStudentCourseLesson) => {
      await courseActions.handleVideoTime({
        data,
        state: {
          modules,
        },
        onSuccess: newState =>
          setState(prevState => ({ ...prevState, ...newState })),
      });
    },
    [modules],
  );

  const goNextLesson = useCallback(async () => {
    await courseActions.goNextLesson({
      state,
      onSuccess: newState =>
        setState(prevState => ({ ...prevState, ...newState })),
    });
  }, [state]);

  const newGoNextLesson = useCallback(async () => {
    await courseActions.newGoNextLesson({
      state,
      onSuccess: newState =>
        setState(prevState => ({ ...prevState, ...newState })),
    });
  }, [state]);

  const getAllLessonTypeQuiz = useCallback(
    (data: { courseId: string; studentId: string }) =>
      courseActions.getAllLessonTypeQuiz(data),
    [],
  );

  const getAllLessonsQuiz = useCallback(
    (data: { courseId: string; studentId: string; courseSlug: string }) =>
      courseActions.getAllLessonsQuiz(data),
    [],
  );

  const getAllLessonTypeLiveZoom = useCallback(
    (data: { courseId: string; studentId: string }) =>
      courseActions.getAllLessonTypeLiveZoom(data),
    [],
  );

  const allLessons = useMemo(
    () => courseUtils.getAllLessons(modules || []),
    [modules],
  );

  const handleCurrentLessonBySlug = useCallback(
    (data: { lessonSlug: string }) =>
      courseActions.handleCurrentLessonBySlug({
        data,
        state: {
          course,
          modules,
        },
        onSuccess: newState =>
          setState(prevState => ({ ...prevState, ...newState })),
      }),
    [course, modules],
  );

  const handleCurrentLessonBySlugAndId = useCallback(
    (data: {
      lessonSlug: string;
      courseLessonId: string;
      isInitial?: boolean;
    }) =>
      courseActions.handleCurrentLessonBySlug({
        data,
        state: {
          course,
          modules,
        },
        onSuccess: newState =>
          setState(prevState => ({ ...prevState, ...newState })),
      }),
    [course, modules],
  );

  const updateAdditionalInformations = useCallback(
    (data: IUpdateStudentCourseAdditionalInformations) =>
      courseActions.updateAdditionalInformations({
        data,
        onSuccess: newState =>
          setState(prevState => ({ ...prevState, ...newState })),
        state: {
          course,
        },
      }),
    [course],
  );

  useEffect(() => {
    if (
      query?.slug &&
      query.slug !== '[slug]' &&
      query.slug !== course?.slug &&
      user?._id
    ) {
      getCourse({ courseSlug: query.slug as string });
    }
  }, [course?.slug, getCourse, query, user?._id]);

  return (
    <CourseContext.Provider
      value={{
        course,
        modules,
        currentLesson,
        loading,
        handleCheckLesson,
        handleCurrentLesson,
        handleVideoTime,
        goNextLesson,
        newGoNextLesson,
        getAllLessonTypeQuiz,
        getAllLessonsQuiz,
        handleCurrentLessonBySlug,
        handleCurrentLessonBySlugAndId,
        getAllLessonTypeLiveZoom,
        allLessons,
        updateAdditionalInformations,
      }}
    >
      {children}
    </CourseContext.Provider>
  );
};

const useCourse = (): ICourseContext => {
  const loading = useContextSelector(CourseContext, (ctx: any) => ctx.loading);
  const course = useContextSelector(CourseContext, (ctx: any) => ctx.course);
  const modules = useContextSelector(CourseContext, (ctx: any) => ctx.modules);
  const allLessons = useContextSelector(
    CourseContext,
    (ctx: any) => ctx.allLessons,
  );
  const currentLesson = useContextSelector(
    CourseContext,
    (ctx: any) => ctx.currentLesson,
  );
  const getAllLessonTypeLiveZoom = useContextSelector(
    CourseContext,
    (ctx: any) => ctx.getAllLessonTypeLiveZoom,
  );

  const getAllLessonTypeQuiz = useContextSelector(
    CourseContext,
    (ctx: any) => ctx.getAllLessonTypeQuiz,
  );

  const getAllLessonsQuiz = useContextSelector(
    CourseContext,
    (ctx: any) => ctx.getAllLessonsQuiz,
  );

  const goNextLesson = useContextSelector(
    CourseContext,
    (ctx: any) => ctx.goNextLesson,
  );

  const newGoNextLesson = useContextSelector(
    CourseContext,
    (ctx: any) => ctx.newGoNextLesson,
  );

  const handleCheckLesson = useContextSelector(
    CourseContext,
    (ctx: any) => ctx.handleCheckLesson,
  );
  const handleCurrentLesson = useContextSelector(
    CourseContext,
    (ctx: any) => ctx.handleCurrentLesson,
  );
  const handleCurrentLessonBySlug = useContextSelector(
    CourseContext,
    (ctx: any) => ctx.handleCurrentLessonBySlug,
  );
  const handleCurrentLessonBySlugAndId = useContextSelector(
    CourseContext,
    (ctx: any) => ctx.handleCurrentLessonBySlugAndId,
  );
  const handleVideoTime = useContextSelector(
    CourseContext,
    (ctx: any) => ctx.handleVideoTime,
  );
  const updateAdditionalInformations = useContextSelector(
    CourseContext,
    (ctx: any) => ctx.updateAdditionalInformations,
  );

  return {
    course,
    allLessons,
    currentLesson,
    loading,
    modules,
    getAllLessonTypeLiveZoom,
    getAllLessonTypeQuiz,
    goNextLesson,
    newGoNextLesson,
    handleCheckLesson,
    handleCurrentLesson,
    handleCurrentLessonBySlug,
    handleCurrentLessonBySlugAndId,
    handleVideoTime,
    updateAdditionalInformations,
    getAllLessonsQuiz,
  };
};

export { useCourse, CourseProvider };
