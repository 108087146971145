import { withStyles, IconButton } from '@material-ui/core';

export const Container: any = withStyles(() => ({
  root: {
    padding: '12px 18px',
    marginRight: '-18px',
    '& svg': {
      color: '#333333',
      width: '18px',
      height: '18px',
    },
  },
}))(IconButton);
