import { purchasesController, customersController } from '@services/checkout';
import { ICustomerUpdate } from '@utils/interfaces/customer';
import {
  IPurchaseDetailsRequest,
  IPurchasesListRequest,
} from '@utils/interfaces/purchase';

import { IPurchaseFunctions, IPurchaseState } from './types';

export default class PurchaseActions {
  public async index({
    data,
    state,
    onError,
    onLoad,
    onSuccess,
  }: Pick<
    IPurchaseFunctions<
      IPurchasesListRequest,
      Pick<IPurchaseState, 'purchasesList' | 'hasMore'>,
      Pick<IPurchaseState, 'purchasesList'>
    >,
    'data' | 'onError' | 'onLoad' | 'onSuccess' | 'state'
  >): Promise<void> {
    try {
      onLoad(true);
      const purchasesList = await purchasesController.index(data);

      let hasMore = false;
      const { count, data: newData } = purchasesList;
      const newPurchases = state.purchasesList.data
        ? [...state.purchasesList.data, ...newData]
        : newData;

      if (newData?.length) {
        hasMore = newPurchases.length < count;
      }

      onSuccess({
        purchasesList: {
          data: newPurchases,
          count,
        },
        hasMore,
      });
    } catch (err) {
      onError(err);
    } finally {
      onLoad(false);
    }
  }

  public async show({
    data,
    onError,
    onLoad,
    onSuccess,
  }: Pick<
    IPurchaseFunctions<
      IPurchaseDetailsRequest,
      Pick<IPurchaseState, 'purchase'>
    >,
    'data' | 'onError' | 'onLoad' | 'onSuccess'
  >): Promise<void> {
    try {
      onLoad(true);
      const purchase = await purchasesController.show(data);
      onSuccess({
        purchase,
      });
    } catch (err) {
      onError(err);
    } finally {
      onLoad(false);
    }
  }

  public async updateAddress({
    data,
    state,
    onError,
    onLoad,
    onSuccess,
  }: IPurchaseFunctions<
    ICustomerUpdate,
    Pick<IPurchaseState, 'purchase'>,
    Pick<IPurchaseState, 'purchase'>
  >): Promise<void> {
    try {
      onLoad(true);
      await customersController.update(data);
      onSuccess({
        purchase: {
          ...state.purchase,
          customer: {
            ...state.purchase.customer,
            ...data,
          },
        },
      });
    } catch (err) {
      onError(err);
    } finally {
      onLoad(false);
    }
  }
}
