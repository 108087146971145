import { useLoading } from '@hooks/loading';
import { useUser } from '@hooks/user';

import * as AuthService from '@services/auth';
import { useGa } from '@startse/react-components';
import { getCourses, getStatusCourse } from '@services/dash';
import { COURSE_STATUS } from '@utils/constants/courseStatus';
import cookies from '@utils/cookies';
import AUTH_TOKEN_COOKIE, {
  ATTENDANCE_CONFIRMATION_EXPIRATION,
  AUTH_USER_TOKEN,
} from '@utils/cookies/authCookie';
import { getCurrentCourseToAttendance } from '@utils/coursesAttendances/currentCourseToAttendance';
import { ICourseAttendance, ICourseStatus } from '@utils/interfaces/course';
import { COOKIE_NAME } from '@utils/middleware/cookies';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { StudentEnrollmentService } from '@services/content';
import { MoonshotController } from '@infra/controllers/moonshot/MoonshotController';
import { IUser } from '@utils/interfaces/user';
import { removeCPFMask } from '@utils/removeMask';
import { useTracking } from './tracking';
import { useTrackingSegment } from './segment';

interface IAuthContextData {
  loginURL: string;
  showModalLogin: boolean;
  setShowModalLogin(value: boolean): void;
  resetPasswordURL: string;
  showModalResetPassword: boolean;
  setShowModalResetPassword(value: boolean): void;
  showLoginCloseButton: boolean;
  setShowLoginCloseButton(value: boolean): void;
  logout(redirect?: string): void;
  showModalCompleted: boolean;
  setShowModalCompleted: (value: boolean) => void;
  validateLogged: () => Promise<void>;
  courseToAttendance: ICourseAttendance;
  showModalConfirmPresence: boolean;
  setShowModalConfirmAttendance(value: boolean): void;
  authChecked: boolean;
}

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const { setUser } = useUser();
  const { addLoading, removeLoading } = useLoading();
  const [showModalLogin, setShowModalLogin] = useState(false);
  const [showLoginCloseButton, setShowLoginCloseButton] = useState(false);
  const [resetPasswordURL, setResetPasswordURL] = useState('');
  const [showModalResetPassword, setShowModalResetPassword] = useState(false);
  const [showModalCompleted, setShowModalCompleted] = useState(false);
  const [authChecked, setAuthChecked] = useState(false);
  const { push, locale, query, pathname, replace, asPath } = useRouter();
  const { identify, track } = useTracking();
  const { push: pushDataLayer } = useGa();
  const { identifySegment } = useTrackingSegment();
  const [currentUrl, setCurrentUrl] = useState('');
  const [courseToAttendance, setCourseToAttendance] =
    useState<ICourseAttendance>({} as ICourseAttendance);
  const [showModalConfirmPresence, setShowModalConfirmAttendance] =
    useState(false);
  const [course, setCourse] = useState(null);
  const [status, setStatus] = useState<number>();

  const onboardingInitialDate = dayjs('2022-04-24');
  const moonshotController = new MoonshotController();

  const loginURL = useMemo(
    () =>
      `${process.env.NEXT_PUBLIC_AUTH_CLIENT}authentication/signinV2?lng=${locale}&redirect_uri=${currentUrl}&client_view=platform`,
    [currentUrl, locale],
  );

  const logout = (): void => {
    cookies.remove(AUTH_TOKEN_COOKIE);
    cookies.remove(AUTH_USER_TOKEN);
    cookies.remove(ATTENDANCE_CONFIRMATION_EXPIRATION);

    window.location.href = `${process.env.NEXT_PUBLIC_AUTH_API}api/auth/signout?redirect_to=${currentUrl}`;
  };

  const validateLogged = async (): Promise<any> => {
    addLoading();

    try {
      const { user, token } = await AuthService.getUser();
      cookies.set(AUTH_USER_TOKEN, token);
      if (!user || !user._id) {
        if (asPath === '/?contratante=true') {
          push('/?contratante=true');
        } else if (asPath === '/?techAcademy=true') {
          push('/?techAcademy=true');
        } else {
          push('/');
          throw new Error();
        }
      }

      const authStartseDate = sessionStorage.getItem('authStartseDate');

      if (authStartseDate && user._id) {
        pushDataLayer({
          event: 'Identify',
          email: user.email,
        });
        sessionStorage.removeItem('authStartseDate');
        const trackIdentify = {};
        if (user.document) {
          Object.assign(trackIdentify, {
            userId: removeCPFMask(user.document),
          });
        }
        identifySegment('', {
          ...trackIdentify,
          email: user.email,
        });
      }
      setUser(user);
      identify(user.email);

      if (asPath === '/?contratante=true') {
        addLoading();
        await StudentEnrollmentService.findByUserIdAndCourseId(
          user?._id,
          process.env.NEXT_PUBLIC_HIRER_COURSE,
        )
          .then(async (res: any) => {
            if (res.count > 0)
              push(`/curso/${res?.rows[0]?.course?.slug}/talentos`);
            else {
              const payload = {
                course: process.env.NEXT_PUBLIC_HIRER_COURSE,
                expiresAt: null,
                partnerCertificate: '',
                student: user?._id,
              };

              const userData = {
                email: user?.email,
                firstName: user?.firstName,
                lastName: user?.lastName,
                hirer: true,
              } as IUser;

              await moonshotController
                .createUpdateUser({ input: userData })
                .catch(err => {
                  console.log(err);
                  removeLoading();
                });

              await StudentEnrollmentService.create(payload)
                .then(async (res: any) => {
                  push(`/curso/${res?.course?.slug}/talentos`);
                })
                .catch(error => console.log(error))
                .finally(() => removeLoading());
            }
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => removeLoading());
      }

      if (asPath === '/?techAcademy=true') {
        addLoading();
        await StudentEnrollmentService.findByUserIdAndCourseId(
          user?._id,
          process.env.NEXT_PUBLIC_TECH_ACADEMY_COURSE,
        )
          .then(async (res: any) => {
            if (res.count > 0) push(`/curso/${res?.rows[0]?.course?.slug}`);
            else {
              const payload = {
                course: process.env.NEXT_PUBLIC_TECH_ACADEMY_COURSE,
                expiresAt: null,
                partnerCertificate: '',
                student: user?._id,
              };

              const userData = {
                email: user?.email,
                firstName: user?.firstName,
                lastName: user?.lastName,
                hirer: true,
              } as IUser;

              await moonshotController
                .createUpdateUser({ input: userData })
                .catch(err => {
                  console.log(err);
                  removeLoading();
                });

              await StudentEnrollmentService.create(payload)
                .then(async (res: any) => {
                  push(`/curso/${res?.course?.slug}`);
                })
                .catch(error => console.log(error))
                .finally(() => removeLoading());
            }
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => removeLoading());
      }

      if (user?.courtesyEvent && !user?.completedOnboarding) {
        replace({
          pathname: `${process.env.NEXT_PUBLIC_ONBOARDING}`,
          query: { redirect: window.location.href },
        });
      }

      if (
        user.creatAccountOn !== undefined &&
        dayjs(user.creatAccountOn).isAfter(onboardingInitialDate) &&
        !user.completedOnboarding &&
        !cookies.get(ATTENDANCE_CONFIRMATION_EXPIRATION)
      ) {
        const attendanceExpiration = 172800;
        cookies.set(
          ATTENDANCE_CONFIRMATION_EXPIRATION,
          ATTENDANCE_CONFIRMATION_EXPIRATION,
          { maxAge: attendanceExpiration },
        );
        setShowModalCompleted(true);
        track('Modal onboarding appeared', { where: 'LMS' });
      }

      if (cookies.get(COOKIE_NAME.FT_CHECKIN) !== 'false') {
        const courseAttendance = await getCourses(user.email);
        setCourse(courseAttendance);
      }

      return user;
    } catch (error) {
      // eslint-disable-next-line no-console
      setAuthChecked(true);
      console.log(error);
      return null;
    } finally {
      setAuthChecked(true);
      removeLoading();
    }
  };

  const getCourseBySlug = async (data: ICourseAttendance): Promise<void> => {
    const courseBySlug = {
      email: data?.email,
      productEditionId: data?.slug,
    } as ICourseStatus;

    if (cookies.get(COOKIE_NAME.FT_CHECKIN) !== 'false') {
      await getStatusCourse(courseBySlug).then(course =>
        setStatus(course?.course_status),
      );
    }
  };

  useEffect(() => {
    let currentCourseToAttendance;
    if (cookies.get(COOKIE_NAME.FT_CHECKIN) !== 'false') {
      currentCourseToAttendance = getCurrentCourseToAttendance(course, 30);
      getCourseBySlug(currentCourseToAttendance);
    }

    if (
      course?.length > 0 &&
      !showModalCompleted &&
      status === COURSE_STATUS.confirmPresence &&
      cookies.get(COOKIE_NAME.FT_CHECKIN) !== 'false'
    ) {
      track('Modal checkin appeared', { courseName: course?.course });
      setCourseToAttendance(currentCourseToAttendance);
      setShowModalConfirmAttendance(true);
    }
  }, [course, showModalCompleted, status, track]);

  useEffect(() => {
    setCurrentUrl(window.location.href);

    if (
      !process.env.NEXT_PUBLIC_AUTH_API ||
      !process.env.NEXT_PUBLIC_AUTH_CLIENT
    )
      return;

    if (!cookies.get(AUTH_TOKEN_COOKIE)) {
      setAuthChecked(true);
      const paths = pathname.split('/');
      if (paths[1] === 'minhas-compras' && query?.lead) {
        const { linkResetPassword } = JSON.parse(
          Buffer.from(String(query?.lead), 'base64').toString('ascii'),
        );
        if (linkResetPassword) {
          setShowModalResetPassword(true);
          setResetPasswordURL(linkResetPassword);
        }
        setShowLoginCloseButton(false);
      } else if (asPath === '/?contratante=true') {
        push('/?contratante=true');
      } else if (asPath === '/?techAcademy=true') {
        push('/?techAcademy=true');
      } else {
        push('/');
      }
      return;
    }

    validateLogged();
    // eslint-disable-next-line
  }, []);

  return (
    <AuthContext.Provider
      value={{
        logout,
        loginURL,
        showModalLogin,
        setShowModalLogin,
        showModalCompleted,
        setShowModalCompleted,
        showLoginCloseButton,
        setShowLoginCloseButton,
        showModalResetPassword,
        setShowModalResetPassword,
        resetPasswordURL,
        validateLogged,
        courseToAttendance,
        showModalConfirmPresence,
        setShowModalConfirmAttendance,
        authChecked,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): IAuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('Auth must be used within a AuthProvider');
  }

  return context;
}

export { useAuth, AuthProvider };
