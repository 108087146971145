import { createTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});

const theme = createTheme({
  typography: {
    fontFamily: 'IBM Plex Sans',
    h1: {
      fontFamily: 'Barlow',
      fontSize: 36,
      lineHeight: '44px',
      fontWeight: 600,
      [breakpoints.down('sm')]: {
        fontSize: 23,
        lineHeight: '35px',
      },
    },
    h2: {
      fontFamily: 'Barlow',
      fontSize: 24,
      lineHeight: '25px',
      fontWeight: 600,
    },
    h3: {
      fontFamily: 'Barlow',
      fontSize: 19,
      lineHeight: '24px',
    },
    h4: {
      fontFamily: 'Barlow',
      fontSize: 16,
      lineHeight: '19px',
    },
    h5: {
      fontSize: 14,
      fontFamily: 'Barlow',
      textTransform: 'none',
    },
    subtitle1: {
      fontFamily: 'IBM Plex Sans',
      fontSize: 19,
      lineHeight: '24px',
    },
    body1: {
      fontFamily: 'IBM Plex Sans',
      fontSize: 16,
      lineHeight: '20px',
    },
    overline: {
      fontFamily: 'Barlow',
      fontSize: 16,
      lineHeight: '19px',
      fontWeight: 500,
    },
  },
  palette: {
    primary: {
      main: '#0080ED',
      light: '#3D9DEF',
    },
    secondary: {
      main: '#ADB1BF',
    },
    text: {
      primary: '#333333',
      hint: '#818181',
      secondary: '#707070',
    },
  },
});

export default theme;
