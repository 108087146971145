import axios from 'axios';
import cookies from '@utils/cookies';
import AUTH_TOKEN_COOKIE, { AUTH_USER_TOKEN } from '@utils/cookies/authCookie';

const api = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_AUTH_API}api/`,
});

api.interceptors.request.use(config => {
  const authToken = cookies.get(AUTH_TOKEN_COOKIE);
  if (!authToken) throw new axios.Cancel('Operation canceled by the user.');

  Object.assign(config.headers, {
    Authorization: `Bearer ${authToken}`,
  });

  return config;
});

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      cookies.remove(AUTH_TOKEN_COOKIE);
      cookies.remove(AUTH_USER_TOKEN);
    }
    return error;
  },
);

export default api;
