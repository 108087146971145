import { styled } from '@material-ui/core';

export const ContainerFullLoading: any = styled('div')({
  position: 'fixed',
  zIndex: 999,
  width: '100vw',
  height: '100vh',
  top: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
