import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { useTranslation } from 'next-i18next';

import { Container } from './styles';

const useStyles = makeStyles({
  root: {
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    padding: '4px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
    },
    '&:active': {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
    '&:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
  },
});

export interface IBackButton {
  onClick?: () => void;
}

const BackButton: React.FC<IBackButton> = ({ onClick }) => {
  const { t } = useTranslation('common');
  const { root } = useStyles();

  return (
    <Container onClick={onClick}>
      <IconButton className={root} aria-label="voltar">
        <NavigateBeforeIcon />
      </IconButton>
      <p>{t('common.go_back')}</p>
    </Container>
  );
};

export default BackButton;
