import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { createContext, useContext, useEffect, useState } from 'react';
import { useCourse } from './course';

const AdditionInformationsModal: any = dynamic(
  () => import('@components/core/AdditionalInformationsModal'),
  { ssr: false },
);

interface IAdditionalInformationsContextData {
  showAdditionalInformationsModal: boolean;
  setShowAdditionalInformationsModal: (
    showAdditionalInformationsModal: boolean,
  ) => void;
}

const AdditionalInformationsContext =
  createContext<IAdditionalInformationsContextData>(
    {} as IAdditionalInformationsContextData,
  );

const AdditionalInformationsProvider: React.FC = ({ children }) => {
  const { course, loading } = useCourse();
  const router = useRouter();
  const [showAdditionalInformationsModal, setShowAdditionalInformationsModal] =
    useState(false);

  useEffect(() => {
    if (!loading) {
      if (router?.pathname === '/' && showAdditionalInformationsModal)
        setShowAdditionalInformationsModal(false);
      else if (
        course?.additionalInformations &&
        !course?.studentAdditionalInformations
      ) {
        setShowAdditionalInformationsModal(true);
      } else {
        setShowAdditionalInformationsModal(false);
      }
    } else {
      setShowAdditionalInformationsModal(false);
    }
  }, [course, loading, router?.pathname, showAdditionalInformationsModal]);

  return (
    <AdditionalInformationsContext.Provider
      value={{
        setShowAdditionalInformationsModal,
        showAdditionalInformationsModal,
      }}
    >
      {showAdditionalInformationsModal && (
        <AdditionInformationsModal
          onClose={() => setShowAdditionalInformationsModal(false)}
        />
      )}
      {children}
    </AdditionalInformationsContext.Provider>
  );
};

function useAdditionalInformations(): IAdditionalInformationsContextData {
  const context = useContext(AdditionalInformationsContext);

  if (!context) {
    throw new Error(
      'AdditionalInformations must be used within a AdditionalInformationsProvider',
    );
  }

  return context;
}

export { useAdditionalInformations, AdditionalInformationsProvider };
