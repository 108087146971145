import { SvgIcon, SvgIconProps } from '@material-ui/core';

const CloseIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    className="startse-icon"
    viewBox="0 0 14 14"
    fontSize="small"
    {...props}
  >
    <path d="M11.251,13.567l-.187-.16L6.944,9.286,2.83,13.4a1.655,1.655,0,0,1-2.5-2.155l.16-.185L4.6,6.946.483,2.824A1.655,1.655,0,0,1,2.638.324l.185.162,4.12,4.121L11.061.489a1.655,1.655,0,0,1,2.5,2.155l-.16.185L9.284,6.946,13.4,11.067a1.654,1.654,0,0,1-2.153,2.5Z" />
  </SvgIcon>
);
export default CloseIcon;
