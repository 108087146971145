import axios from 'axios';
import cookies from '@utils/cookies';
import AUTH_TOKEN_COOKIE from '@utils/cookies/authCookie';

const api = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_CHECKIN_URL}/api`,
});

api.interceptors.request.use(config => {
  const authToken = cookies.get(AUTH_TOKEN_COOKIE);

  if (!authToken) throw new axios.Cancel('Operation canceled by the user;');

  Object.assign(config.headers, {
    Authorization: `${process.env.NEXT_PUBLIC_DASH_API_KEY}`,
  });

  return config;
});

export default api;
