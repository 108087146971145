import { useCallback } from 'react';
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
} from 'react-hook-form';
import { StandardTextFieldProps } from '@material-ui/core';
import InputMask from 'react-input-mask';

import { TextFieldUI } from './styles';

interface InputProps extends StandardTextFieldProps {
  maskPlaceholder?: string;
  mask?: string | (string | RegExp)[];
  control?: any;
  errorMessage?: string;
}

const Input: React.FC<InputProps> = ({
  maskPlaceholder,
  mask,
  errorMessage,
  control,
  name,
  ...rest
}) => {
  const InputMaskk: any = InputMask;
  const inputWithMask = useCallback(
    ({
      onChange,
      onBlur,
      value,
    }: ControllerRenderProps<FieldValues, string>) => (
      <InputMaskk
        mask={mask}
        maskChar={maskPlaceholder || ''}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
      >
        {(inputProps: JSX.IntrinsicAttributes) => (
          <TextFieldUI
            size="small"
            variant="outlined"
            id="outlined-basic"
            fullWidth
            error={!!errorMessage}
            helperText={errorMessage}
            {...inputProps}
            {...rest}
          />
        )}
      </InputMaskk>
    ),
    [InputMaskk, errorMessage, mask, maskPlaceholder, rest],
  );

  const normalInput = useCallback(
    ({
      onChange,
      onBlur,
      value,
    }: ControllerRenderProps<FieldValues, string>) => (
      <TextFieldUI
        size="small"
        variant="outlined"
        id="outlined-basic"
        fullWidth
        error={!!errorMessage}
        helperText={errorMessage}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        {...rest}
      />
    ),
    [errorMessage, rest],
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (mask ? inputWithMask(field) : normalInput(field))}
    />
  );
};

export default Input;
