import React, { useCallback, useEffect, useMemo, useState, memo } from 'react';

import { useRouter } from 'next/router';
import { useUser } from '@hooks/user';
import { useAuth } from '@hooks/auth';
import { useTracking } from '@hooks/tracking';
import {
  NewHeaderPlatform,
  NewMenuOption,
  RatingModal,
} from '@startse/react-components';
import ShareArea from '@components/core/Share/ShareArea';
import cookies from '@utils/cookies';

const Header: React.FC = () => {
  const { user } = useUser();
  const { setShowModalLogin, logout } = useAuth();
  const { track } = useTracking();
  const [evaluatingSpeaker, setEvaluatingSpeaker] = useState('');

  const userData = useMemo(() => {
    return {
      id: user?._id,
      photoUrl: user?.profileImageURL,
      name: `${user?.firstName} ${user?.lastName}`,
    };
  }, [user?._id, user?.firstName, user?.lastName, user?.profileImageURL]);

  const notificationIntervalInMilliseconds =
    process?.env?.NEXT_PUBLIC_NOTIFICATION_INTERVAL_IN_MILLISECONDS;

  const { push, locale, asPath } = useRouter();

  useEffect(() => {
    cookies.set('startseLocale', locale);
  }, [locale]);

  const handleMenu = useCallback(
    (path: string, isInside) => {
      if (isInside) push(path);
    },
    [push],
  );

  const handleOutsideLink = useCallback(
    (path: string) => {
      if (!path) return;
      switch (path) {
        case '/eventos':
          track('Click menu Events', {
            namePage: window.location.href,
          });
          break;
        case '/webseries':
          track('Click menu Webserie', {
            namePage: window.location.href,
          });
          break;
        case '/podcasts':
          track('Click menu Podcast', {
            namePage: window.location.href,
          });
          break;
        default:
      }
      if (path === process.env.NEXT_PUBLIC_STARTSE_CORPORATE) {
        window.open(path, '_blank');
        return;
      }
      if (path.includes('https://')) window.location.href = path;
      if (path === '/') {
        if (
          window.location.href === `${process.env.NEXT_PUBLIC_APP}` &&
          asPath !== '/'
        ) {
          push(`/${locale}`);
        }
        push(`/${locale}`);
      } else
        window.location.href = `${process.env.NEXT_PUBLIC_PLATFORM}/${path}`;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [track],
  );

  const handleMenuClick = useCallback(
    (option: NewMenuOption) => {
      if (!option.utmSource) {
        handleMenu(option.baseUrl, false);
      } else handleMenu(option.path, option.utmSource === 'lms');
    },
    [handleMenu],
  );

  return (
    <>
      <NewHeaderPlatform
        handleMenuClick={option => {
          handleMenuClick(option);
          handleOutsideLink(option.path);
        }}
        onPressLogo={() => handleOutsideLink('/')}
        logoutOnClick={logout}
        noUserMenuClicked={() => setShowModalLogin(true)}
        openProfileOnClick={() => {
          window.location.href = process.env.NEXT_PUBLIC_MY_ACCOUNT;
        }}
        user={user && user._id ? userData : undefined}
        onNotificationClick={(link, action) => {
          if (link) document.location.href = link;
          if (action?.type === 'rateEventSpeaker' && action?.data?.activity)
            setEvaluatingSpeaker(action.data.activity);
        }}
        language={locale}
        handleLanguage={newLanguage => {
          push(asPath, asPath, { locale: newLanguage });
        }}
        onSearchFullPage={term => handleMenu(`/busca?q=${term}`, false)}
        onSearchItemClick={item => handleMenu(item.path, false)}
        notificationIntervalInMilliSeconds={notificationIntervalInMilliseconds}
        linkMyAccount={`${process.env.NEXT_PUBLIC_MY_ACCOUNT}`}
        currentLocation="/"
        menuOptions={[]}
      />
      {user?._id && (
        <RatingModal
          closeModalControlled={() => setEvaluatingSpeaker(null)}
          contentId={evaluatingSpeaker}
          showModalControlled={!!evaluatingSpeaker}
          type="eventActivity"
          userId={user._id}
          ShareComponent={<ShareArea />}
        />
      )}
    </>
  );
};

export default memo(Header);
