import { ICourse } from '@utils/interfaces/course';
import { IModulesWithLessons } from '@utils/interfaces/modulesWithLessons';
import { AxiosResponse } from 'axios';
import api from '../api';

export interface IGetCourseByStudentParams {
  courseSlug: string;
}

export interface IResponseGetCourseByStudent {
  course: ICourse;
  modules: IModulesWithLessons[];
}

export const getCourseByStudent = (
  params: IGetCourseByStudentParams,
): Promise<AxiosResponse<IResponseGetCourseByStudent>> =>
  api.get('/lms-student/course-by-student', { params });

export interface IUpdateStudentCourseLesson {
  assistedTime?: number;
  hasCompleted?: boolean;
  courseLesson?: string;
  numberPagePdf?: number;
}

export const updateStudentCourseLesson = (
  body: IUpdateStudentCourseLesson,
): Promise<AxiosResponse<any>> =>
  api.post('/lms-student/update-student-course-lesson', body);

export interface IUpdateStudentCourse {
  course: string;
  hasCompleted: boolean;
  lastAccessLesson: string;
  lastAccessCourseLesson?: string;
  courseLessonId?: string;
  isInitial?: boolean;
}

export const updateStudentCourse = (
  body: IUpdateStudentCourse,
): Promise<AxiosResponse<void>> =>
  api.post('/lms-student/update-student-course', body);

export interface IUpdateStudentCourseAdditionalInformations {
  course: string;
  additionalInformations: string;
}

export const updateStudentCourseAdditionalInformations = (
  body: IUpdateStudentCourseAdditionalInformations,
): Promise<AxiosResponse<void>> =>
  api.post('/lms-student/update-student-course/additional-informations', body);
