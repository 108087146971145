import { useMemo } from 'react';
import dynamic from 'next/dynamic';
import { useMediaQuery, useTheme } from '@material-ui/core';

import webLoadingAnimation from '@assets/animations/webPdfLoading.json';
import mobileLoadingAnimation from '@assets/animations/mobilePdfLoading.json';

import { ContainerFullLoading } from './styles';

const Lottie: any = dynamic(
  () => import('lottie-react').then(module => module.default),
  { ssr: false },
);

interface PdfLoadingProps {
  fullLoading?: boolean;
  size?: number;
}

const PdfLoading: React.FC<PdfLoadingProps> = ({
  fullLoading = true,
  size = 300,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const defaultOptions = useMemo(
    () => ({
      loop: true,
      autoplay: true,
      isClickToPauseDisabled: true,
      animationData: isMobile ? mobileLoadingAnimation : webLoadingAnimation,
    }),
    [isMobile],
  );
  const renderLottie = useMemo(
    () => (
      <Lottie
        {...defaultOptions}
        style={{ height: size, width: size, flex: 1 }}
      />
    ),
    [defaultOptions, size],
  );

  if (!fullLoading) return renderLottie;
  return <ContainerFullLoading>{renderLottie}</ContainerFullLoading>;
};

export default PdfLoading;
