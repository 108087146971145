import { styled } from '@material-ui/core';

export const Container: any = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',

  '& p': {
    marginLeft: '5px',
    fontFamily: 'Barlow',
    fontSize: '14px',
    textTransform: 'uppercase',
  },

  [theme.breakpoints.down('sm')]: {
    margin: '5px 0',
    '& p': {
      display: 'none',
    },
    '& svg ': {
      width: '20px',
      height: '20px',
    },
  },
}));
