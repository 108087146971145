export enum SEGMENT_EVENTS {
  PAGE_VIEWED = 'Page Viewed',
  LESSON_COMPLETED = 'Lesson Completed',
  LESSON_STARTED = 'Lesson Started',
  CERTIFICATE_DOWNLOADED = 'Certificate Downloaded',
  CERTIFICATE_SHARED = 'Certificate Shared',
  INTEREST_FORM_COMPLETED = 'Interest Form Completed',
  PRODUCT_VIEW = 'Product View',
  PRODUCT_ADDED = 'Product Added',
  PARTICIPANT_REGISTER = 'Participant Registration',
}

export enum SEGMENT_APP_TYPE {
  SHOWCASE = 'Vitrine',
  CHECKOUT = 'Novo CHeckout',
  LMS = 'Curso',
  APP = 'Artigo',
  WWW = 'Produto',
}

export enum SEGMENT_PAGE_VIEWED_NAMES {
  COURSES_PAGE = 'Área do aluno: Courses page',
  HOME_PAGE = 'Área do aluno: HomePage',
  CLASSES = 'Área do aluno: Classes',
  SCHEDULE = 'Área do aluno: Schedule',
  NETWORKING = 'Área do aluno: Networking',
  MATERIAIS = 'Área do aluno: Materiais',
  CERTIFICATE = 'Área do aluno: Certificate',
  FAQ = 'Área do aluno: FAQ',
  LESSON = 'Área do aluno: Lesson',
  RANKING = 'Área do aluno: Ranking',
  JOURNEY = 'Área do aluno: Journey',
}
