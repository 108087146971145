import { useMemo } from 'react';
import dynamic from 'next/dynamic';

import loadingAnimation from '@assets/animations/loadingAnimation.json';

import { ContainerFullLoading } from './styles';

const Lottie: any = dynamic(
  () => import('lottie-react').then(module => module.default),
  { ssr: false },
);

interface LottieLoadingProps {
  loading: boolean;
  fullLoading?: boolean;
  size?: number;
}

const LottieLoading: React.FC<LottieLoadingProps> = ({
  loading,
  fullLoading = true,
  size = 300,
}) => {
  const defaultOptions = useMemo(
    () => ({
      loop: true,
      autoplay: true,
      isClickToPauseDisabled: true,
      animationData: loadingAnimation,
    }),
    [],
  );
  const renderLottie = useMemo(
    () => (
      <Lottie
        {...defaultOptions}
        style={{ height: size, width: size, flex: 1 }}
      />
    ),
    [defaultOptions, size],
  );

  if (!loading) return null;

  if (!fullLoading) return renderLottie;
  return <ContainerFullLoading>{renderLottie}</ContainerFullLoading>;
};

export default LottieLoading;
