import React from 'react';

import { useTranslation } from 'next-i18next';
import Link from 'next/link';

import { Breadcrumbs, Typography } from '@material-ui/core';
import { BoxBread, LinkText, useStyles } from './styles';

interface IBreadcrumbsProps {
  slug?: string;
}

const BreadcrumbsComponent: React.FC<IBreadcrumbsProps> = ({ slug }) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const breadcrumbs = [
    <Link key="1" href="/">
      <LinkText>{t('breadcrumbs.student_area')}</LinkText>
    </Link>,
    <Typography key="2" className={classes.linkActived}>
      {slug ?? slug}
    </Typography>,
  ];

  return (
    <>
      <BoxBread>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </BoxBread>
    </>
  );
};

export default BreadcrumbsComponent;
