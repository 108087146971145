import {
  ICreateUpdateUserUseCase,
  IGetUserUseCase,
} from '@domain/moonshot/use-cases';
import { CreateUpdateFactory } from '@infra/factories/use-cases/moonshot/CreateUpdateUser';
import { GetUserFactory } from '@infra/factories/use-cases/moonshot/GetUserRepository';

export class MoonshotController {
  async getUser(
    params: IGetUserUseCase.GetUserParams,
  ): Promise<IGetUserUseCase.GetUserResponse> {
    const userController = GetUserFactory();
    return userController.execute(params);
  }

  async createUpdateUser(
    params: ICreateUpdateUserUseCase.CreateUpdateUserParams,
  ): Promise<ICreateUpdateUserUseCase.CreateUpdateUserResponse> {
    const createUpdateController = CreateUpdateFactory();
    return createUpdateController.execute(params);
  }
}
