import { AxiosError } from 'axios';

export const getMessageByError = (error: AxiosError): string => {
  switch (error.response?.data?.message) {
    case 'Participant repeated.':
      return 'participant_repeated';
    case 'Participant Wrong Quantity.':
      return 'participant_wrong_quantity';
    case 'Participant email invalid.':
      return 'participant_email_invalid';
    case 'Participant not found.':
      return 'participant_not_found';
    case 'It is not possible to change the participant.':
      return 'not_possible_change_participant';
    case 'Invalid participant.':
      return 'invalid_participant';
    case 'Invalid purchase.':
      return 'invalid_purchase';
    case 'Unable to list participant.':
      return 'unable_to_list_participant';
    case 'Customer not found':
      return 'customer_not_found';
    case 'Unable to update customer.':
      return 'unable_to_update_customer';
    case 'Purchase Not Found.':
      return 'purchase_not_found';

    default:
      return 'internal_error';
  }
};
