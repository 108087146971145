import ResultsNotFound from '@domain/moonshot/errors/ResultsNotFound';
import { IMoonshotProvider } from '../../UserMoonshotProvider';
import { IGetUserUseCase } from './IGetUserUseCase';

export default class GetUserUseCase implements IGetUserUseCase {
  // eslint-disable-next-line no-useless-constructor
  constructor(private moonshotProvider: IMoonshotProvider) {}

  async execute(
    params: IGetUserUseCase.GetUserParams,
  ): Promise<IGetUserUseCase.GetUserResponse> {
    const user = await this.moonshotProvider.getUser(params);

    if (!user) {
      throw new ResultsNotFound();
    }

    return user;
  }
}
