import Cookies, { CookieSetOptions } from 'universal-cookie';

interface ICookie {
  get(name: string): string;
  set(name: string, value: string, options?: CookieSetOptions): void;
  remove(name: string): void;
}

const cookiesInstance = new Cookies();
const cookies: ICookie = {} as ICookie;
const options: CookieSetOptions = {
  domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
  path: '/',
};

cookies.get = (name: string): string => cookiesInstance.get(name);

cookies.set = (name, value, optionValues) =>
  cookiesInstance.set(name, value, optionValues || options);

cookies.remove = name => cookiesInstance.remove(name, options);

export default cookies;
