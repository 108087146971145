import { styled, Theme } from '@material-ui/core';

export const Container: any = styled('div')(({ theme }) => ({
  maxWidth: '1280px',
  margin: 'auto',

  [theme.breakpoints.down('sm')]: {
    backgroundColor: '#FFF',
  },
}));

interface ContainerMenuProps {
  isEnable?: boolean;
  isSelected?: boolean;
  theme: Theme;
}

export const ContainerMenu: any = styled('div')(
  (props: ContainerMenuProps) => ({
    marginRight: '24px',
    cursor: props.isEnable ? 'pointer' : 'initial',
    opacity: props.isEnable ? '1' : '0',
    [props.theme.breakpoints.down('sm')]: {
      marginRight: '16px',
    },
  }),
);

export const Label: any = styled('p')((props: ContainerMenuProps) => ({
  fontFamily: 'Barlow',
  fontSize: '1.32rem',
  whiteSpace: 'nowrap',
  fontWeight: props.isSelected ? 600 : 'normal',
  color: props.isSelected ? '#4285F4' : '#000',
  padding: '0',
  margin: '0 0 10px 0',
  '&:hover': {
    color: '#4285F4',
    fontWeight: 400,
    transition: 'all 0.3s',
    transform: 'translateY(-2px)',
  },
  [props.theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  },
}));

interface BorderBottomProps {
  isSelected?: boolean;
}
export const BorderBottom: any = styled('div')((props: BorderBottomProps) => ({
  height: '6px',
  borderRadius: '3px',
  backgroundColor: '#0080ed',
  width: props.isSelected ? '100%' : '0',
  transition: 'all 2s',
}));
export const ContainerList: any = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '0 56px',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    height: '0px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0 20px',
  },
  [theme.breakpoints.between(1340, 3000)]: {
    padding: '0',
  },
  [theme.breakpoints.between(960, 1279)]: {
    padding: '0 20px',
  },
}));

export const Text: any = styled('span')(({ theme }) => ({
  fontFamily: 'Barlow',
  fontSize: '1.3rem',
  color: '#000000',
  margin: '0 15px 20px 56px',
  display: 'flex',
  padding: '22px 0',

  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    margin: '0 15px 0px 20px',
  },
  [theme.breakpoints.between(1340, 3000)]: {
    margin: '25px 0 0 0px',
  },
}));
export const Title: any = styled('h1')(({ theme }) => ({
  fontFamily: 'Barlow',
  fontSize: '28px',
  fontWeight: 'bold',
  color: '#000000',
  margin: '25px 0 0 56px',
  display: 'flex',

  [theme.breakpoints.down('sm')]: {
    margin: '25px 0 0 20px',
    fontSize: '18px',
    border: '1px solid black',
  },
  [theme.breakpoints.between(1340, 3000)]: {
    margin: '25px 0 0 0px',
  },
}));
