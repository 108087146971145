import {
  ICreateParticipantsRequest,
  IGetParticipantsRequest,
  IParticipant,
  IResendEmailRequest,
  IUpdateParticipantsRequest,
} from '@utils/interfaces/participant';
import api from '../api';

export const create = async ({
  guid,
  participants,
}: ICreateParticipantsRequest): Promise<IParticipant[]> => {
  const enrollment = await api.post<IParticipant[]>(
    `/purchases/${guid}/participants`,
    {
      participants,
    },
  );
  return enrollment.data;
};

export const update = async ({
  guid,
  participants,
}: IUpdateParticipantsRequest): Promise<IParticipant[]> => {
  const { email, firstName, gender, id, lastName, phone } = participants[0];
  const enrollment = await api.put<IParticipant[]>(
    `/purchases/${guid}/participants`,
    {
      participants: [{ email, firstName, gender, id, lastName, phone }],
    },
  );
  return enrollment.data;
};

export const index = async ({
  guid,
}: IGetParticipantsRequest): Promise<IParticipant[]> => {
  const response = await api.get<IParticipant[]>(
    `/purchases/${guid}/participants`,
  );

  return response.data;
};

export const resendEmail = async ({
  guid,
  email,
}: IResendEmailRequest): Promise<IParticipant[]> => {
  const response = await api.post<IParticipant[]>(
    `/purchases/${guid}/participants/resend-email`,
    {
      email,
    },
  );

  return response.data;
};
