import { Box, styled, Theme } from '@material-ui/core';

interface MenuNotFoundProps {
  isMedium?: string;
  theme?: Theme;
}

export const TitleNotFound: any = styled(Box)(
  ({ isMedium, theme }: MenuNotFoundProps) => ({
    fontWeight: 600,
    color: '#333333',
    fontSize: '13px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: isMedium ? '-8px' : '50px',

    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  }),
);
export const ParagraphNotFound: any = styled(Box)(({ theme }) => ({
  color: '#676767',
  fontSize: '12px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  marginTop: '10px',

  [theme.breakpoints.down('sm')]: {
    display: 'flex',
  },
}));

export const ContentBox: any = styled(Box)(
  ({ isMedium }: MenuNotFoundProps) => ({
    margin: isMedium ? '0px' : '0px',
  }),
);
