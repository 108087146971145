import { useMemo } from 'react';
import dynamic from 'next/dynamic';

import emptyAnimation from '@assets/animations/emptyAnimation.json';

const Lottie: any = dynamic(() =>
  import('lottie-react').then(module => module.default),
);

interface LottieEmptyProps {
  size?: number;
}

const LottieEmpty: React.FC<LottieEmptyProps> = ({ size = 400 }) => {
  const defaultOptions = useMemo(
    () => ({
      autoplay: true,
      animationData: emptyAnimation,
      isClickToPauseDisabled: true,
    }),
    [],
  );
  return (
    <Lottie
      {...defaultOptions}
      style={{ height: size, width: size, flex: 1 }}
    />
  );
};

export default LottieEmpty;
