import { SEGMENT_PAGE_VIEWED_NAMES } from './segmentEvent';

export const pathSegment = (path: string): string[] => {
  if (path !== '/') {
    return path.replace('/', '').split('/');
  }

  return ['Homepage'];
};

export const pageViewedName = (path: string): string => {
  const [home, course, courseName, slug, lessonName] = path.split('/');

  function getSlug() {
    let currentSlug = slug;
    if (lessonName) {
      currentSlug = 'lesson';
    }
    return currentSlug;
  }

  if (
    home === '' &&
    course === '' &&
    courseName === undefined &&
    slug === undefined
  ) {
    return SEGMENT_PAGE_VIEWED_NAMES.HOME_PAGE;
  }

  if (home === '' && course !== '' && courseName !== '' && slug === undefined) {
    return SEGMENT_PAGE_VIEWED_NAMES.COURSES_PAGE;
  }

  if (home === '' && course !== '' && courseName !== '' && slug !== '') {
    switch (getSlug()) {
      case 'aulas':
        return SEGMENT_PAGE_VIEWED_NAMES.CLASSES;
      case 'agenda':
        return SEGMENT_PAGE_VIEWED_NAMES.SCHEDULE;
      case 'networking':
        return SEGMENT_PAGE_VIEWED_NAMES.NETWORKING;
      case 'materiais':
        return SEGMENT_PAGE_VIEWED_NAMES.MATERIAIS;
      case 'certificado':
        return SEGMENT_PAGE_VIEWED_NAMES.CERTIFICATE;
      case 'ajuda':
        return SEGMENT_PAGE_VIEWED_NAMES.FAQ;
      case 'lesson':
        return SEGMENT_PAGE_VIEWED_NAMES.LESSON;
      case 'classificacao':
        return SEGMENT_PAGE_VIEWED_NAMES.RANKING;
      case 'jornada':
        return SEGMENT_PAGE_VIEWED_NAMES.JOURNEY;
      default:
    }
  }
  return '';
};
