import { GaProvider } from '@startse/react-components';
import { UserProvider } from './user';
import { AuthProvider } from './auth';
import { LoadingProvider } from './loading';
import { SnackbarProvider } from './snackbar';
import { TrackingProvider } from './tracking';
import { CourseProvider } from './course';
import { AdditionalInformationsProvider } from './additionalInformations';
import { PurchaseProvider, ParticipantProvider } from './checkout';
import { TrackingSegmentProvider } from './segment';
import { RefererProvider } from './referer';
import { PartnerCertificateProvider } from './partnerCertificate';

const AppProvider: React.FC = ({ children }) => (
  <RefererProvider>
    <TrackingSegmentProvider>
      <TrackingProvider>
        <GaProvider>
          <SnackbarProvider>
            <LoadingProvider>
              <UserProvider>
                <AuthProvider>
                  <CourseProvider>
                    <PartnerCertificateProvider>
                      <AdditionalInformationsProvider>
                        <PurchaseProvider>
                          <ParticipantProvider>{children}</ParticipantProvider>
                        </PurchaseProvider>
                      </AdditionalInformationsProvider>
                    </PartnerCertificateProvider>
                  </CourseProvider>
                </AuthProvider>
              </UserProvider>
            </LoadingProvider>
          </SnackbarProvider>
        </GaProvider>
      </TrackingProvider>
    </TrackingSegmentProvider>
  </RefererProvider>
);

export default AppProvider;
