import { styled } from '@material-ui/core/styles';

export const Header: any = styled('div')(({ theme }) => ({
  padding: '0 0 20px 0',
  [theme.breakpoints.down('sm')]: {
    padding: '20px 20px 10px 20px',
  },
}));

export const TitleGroup: any = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  '& a': {
    marginBottom: 10,
    color: '#0080ed',
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export const Title: any = styled('h1')(({ theme }) => ({
  color: '#000000',
  fontFamily: 'Barlow',
  fontWeight: 'bold',
  fontSize: 28,
  margin: 0,
  marginBottom: '10px',
  [theme.breakpoints.down('sm')]: {
    fontSize: 24,
  },
}));

export const Subtitle: any = styled('h2')(({ theme }) => ({
  color: '#000000',
  fontFamily: 'Barlow',
  fontWeight: 'normal',
  fontSize: 24,
  margin: 0,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));
