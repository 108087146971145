import { useEffect } from 'react';
import type { AppProps } from 'next/app';

import { DefaultSeo } from 'next-seo';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import theme from '@styles/theme';
import AppContext from '@hooks/app';
import config from 'config/seo.json';
import { Header, LottieLoading } from '@components/core';
import { useCourse } from '@hooks/course';

import '../assets/css/hubspot-chat.css';
import Menu from '@components/core/Menu';
import { appWithTranslation } from 'next-i18next';
import Login from '@components/core/Login';
import GlobalTrack from '@components/core/GlobalTrack';
import { ContainerApp } from '@styles/home/styles';
import { PageViewedTrack } from '@hooks/segment';
import { GaProvider } from '@hooks/ga';

const AppBeforeLoading: React.FC<any> = ({ Component, pageProps }) => {
  const { loading } = useCourse();

  if (loading) return <LottieLoading loading />;

  return (
    <Menu>
      <Component {...pageProps} />
    </Menu>
  );
};

const MyApp: React.FC<AppProps> = props => {
  useEffect(() => {
    dayjs.locale('pt-br');

    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <>
      <DefaultSeo {...config} />
      <AppContext>
        <GaProvider>
          <ThemeProvider theme={theme}>
            <PageViewedTrack />
            <GlobalTrack />
            <Header />
            <ContainerApp>
              <CssBaseline />
              <AppBeforeLoading {...props} />
              <Login />
            </ContainerApp>
          </ThemeProvider>
        </GaProvider>
      </AppContext>
    </>
  );
};

export default appWithTranslation(MyApp);
