import { ICourseAttendance } from '@utils/interfaces/course';
import dayjs from 'dayjs';

export const getCurrentCourseToAttendance = (
  currentCourse: ICourseAttendance[],
  days: number,
): ICourseAttendance => {
  return currentCourse?.find(
    course => dayjs(new Date()).diff(course.startDate, 'd') <= days,
  );
};
