import {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import cookies from '@utils/cookies';
import AUTH_TOKEN_COOKIE from '@utils/cookies/authCookie';
import { browserName, deviceType, osName } from 'react-device-detect';
import fastq from 'fastq';
import type { queue, done } from 'fastq';

interface TrackingContextData {
  track(eventName: string, props: object): void;
  identify(email: string): void;
}

type TrackTask = {
  eventName: string;
  props: object;
};

const TrackingContext = createContext<TrackingContextData>(
  {} as TrackingContextData,
);

const TrackingProvider: React.FC = ({ children }) => {
  const TASK_DELAY_IN_SECONDS = 0.5;
  const MAX_RETRY_TIME_FOR_FAILED_TASKS = 3;
  const [kissmetricsInstance, setKissmetricsInstance] = useState();
  const worker = useCallback(
    ({ eventName, props: propsWithRetryCount }, callback: done) => {
      const win = window as any;
      let retry = false;
      // eslint-disable-next-line prefer-const
      let { retryCount, ...props } = propsWithRetryCount;

      if (win._kmq) {
        if (cookies.get(AUTH_TOKEN_COOKIE)) {
          props.isLoggedIn = true;
        } else {
          props.isLoggedIn = false;
        }

        props.device = deviceType;
        props.system = osName;
        props.browser = browserName;

        try {
          win._kmq.push(['record', eventName, props]);
        } catch (error) {
          console.error(error);
          // Kissmetrics error => retry
          retry = true;
        }
      } else {
        // Kissmetrics not started yet => retry
        retry = true;
      }

      if (retry) {
        retryCount = retryCount ? retryCount + 1 : 1;

        if (retryCount < MAX_RETRY_TIME_FOR_FAILED_TASKS) {
          props.retryCount = retryCount;
          setTimeout(() => {
            worker({ eventName, props }, callback);
          }, TASK_DELAY_IN_SECONDS * 1000);
          return;
        }
      }

      setTimeout(() => {
        callback(null);
      }, TASK_DELAY_IN_SECONDS * 1000);
    },
    [],
  );
  const [trackQueue] = useState<queue<TrackTask>>(fastq(worker, 1));
  const track = useCallback(
    (eventName, props = {}) => {
      if (!kissmetricsInstance) {
        trackQueue.pause();
      }
      trackQueue.push({ eventName, props });
    },
    [trackQueue, kissmetricsInstance],
  );
  const identify = useCallback(email => {
    const win = window as any;
    if (win._kmq) {
      try {
        win._kmq.push(['identify', email]);
        return true;
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  useEffect(() => {
    if (trackQueue && !kissmetricsInstance) {
      trackQueue.pause();
      return;
    }

    if (kissmetricsInstance) {
      trackQueue.resume();
    }
  }, [trackQueue, kissmetricsInstance]);

  const bodyOnLoad = useCallback(event => {
    const kissmetrics = (window as any)._kmq;

    if (kissmetrics) {
      kissmetrics.push(() => {
        document.dispatchEvent(event);
      });
    } else {
      setTimeout(() => {
        bodyOnLoad(event);
      }, 1000);
    }
  }, []);

  useEffect(() => {
    const eventName = 'kissmetricsReady';
    const event = new CustomEvent(eventName);
    document.addEventListener(eventName, () => {
      setKissmetricsInstance((window as any)._kmq);
    });

    document.querySelector('body').onload = () => bodyOnLoad(event);
  }, [bodyOnLoad]);

  return (
    <TrackingContext.Provider value={{ track, identify }}>
      {children}
    </TrackingContext.Provider>
  );
};

function useTracking(): TrackingContextData {
  const context = useContext(TrackingContext);

  if (!context) {
    throw new Error('Tracking must be used within a TrackingProvider');
  }

  return context;
}

export { useTracking, TrackingProvider };
