import { IResponse } from '@utils/interfaces/response';
import { IStudentEnrollment } from '@utils/interfaces/studentEnrollment';
import api from '../api';

export const findAll = async (): Promise<IResponse<IStudentEnrollment>> => {
  const params = new URLSearchParams();
  const enrollment = await api.get('/lms-student/student-enrollment', {
    params,
  });
  return enrollment.data;
};

export const find = async (id: string): Promise<IStudentEnrollment> => {
  const enrollment = await api.get(`student-enrollment/${id}`);
  return enrollment.data;
};

export const findByUserIdAndCourseId = async (
  userId: string,
  courseId: string,
): Promise<IResponse<IStudentEnrollment>> => {
  const enrollment = await api.get(
    `/lms-student/student-enrollment?filter%5Bcourse%5D=${courseId}&filter%5Bstudent%5D=${userId}`,
  );
  return enrollment.data;
};

export const update = async (
  studentEnrollmentId: string,
  course: string,
  student: string,
  hasEvaluatedNps?: boolean,
  quantityTimesShownNps?: number,
): Promise<IStudentEnrollment> => {
  const hasEvaluated = hasEvaluatedNps || false;
  const hasShownFormQuantity = quantityTimesShownNps || 0;
  const enrollment = await api.put(
    `student-enrollment/${studentEnrollmentId}`,
    {
      data: {
        course,
        student,
        hasEvaluatedNps: hasEvaluated,
        quantityTimesShownNps: hasShownFormQuantity,
      },
      id: studentEnrollmentId,
    },
  );
  return enrollment.data;
};

export const create = async (
  data: unknown,
): Promise<IResponse<IStudentEnrollment>> => {
  const enrollment = await api.post('/lms-student/student-enrollment', {
    data,
  });
  return enrollment.data;
};
