import { useSnackbar } from '@hooks/snackbar';
import { getMessageByError } from '@utils/format/getMessageByError';
import {
  ICreateParticipantsRequest,
  IGetParticipantsRequest,
  IResendEmailRequest,
  IUpdateParticipantsRequest,
} from '@utils/interfaces/participant';
import { useTranslation } from 'next-i18next';
import { useCallback, useState } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import ParticipantsActions from './actions';
import { IParticipantContext, IParticipantState } from './types';

const ParticipantContext: any = createContext<IParticipantContext>(
  {} as IParticipantContext,
);

const initialState: IParticipantState = {
  participants: [],
};

const participantActions = new ParticipantsActions();

export const ParticipantProvider: React.FC = ({ children }) => {
  const { t } = useTranslation('common');
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState<IParticipantState>(initialState);

  const { participants } = state;

  const onLoad = useCallback(load => setLoading(load), []);

  const onError = useCallback(
    err => {
      showSnackbar({
        message: t(`errors.${getMessageByError(err)}`),
        type: 'error',
        duration: 5 * 1000,
      });
    },
    [showSnackbar, t],
  );

  const getParticipants = useCallback(
    (data: IGetParticipantsRequest) =>
      participantActions.index({
        data,
        onSuccess: newState =>
          setState(prevState => ({ ...prevState, ...newState })),
        onError,
        onLoad,
      }),
    [onError, onLoad],
  );

  const createParticipant = useCallback(
    (data: ICreateParticipantsRequest, callToSucces: () => void) =>
      participantActions.create({
        data,
        onSuccess: newState => {
          setState(prevState => ({ ...prevState, ...newState }));
          showSnackbar({
            message: t(
              'my_purchase_details.success_messages.create_participant',
            ),
            type: 'success',
            duration: 3 * 1000,
          });
          callToSucces();
        },
        onError,
        onLoad,
      }),
    [onError, onLoad, showSnackbar, t],
  );

  const updateParticipant = useCallback(
    (data: IUpdateParticipantsRequest, callToSucces: () => void) =>
      participantActions.update({
        data,
        state: {
          participants,
        },
        onSuccess: newState => {
          setState(prevState => ({ ...prevState, ...newState }));
          showSnackbar({
            message: t(
              'my_purchase_details.success_messages.update_participant',
            ),
            type: 'success',
            duration: 3 * 1000,
          });
          callToSucces();
        },
        onError,
        onLoad,
      }),
    [onError, onLoad, participants, showSnackbar, t],
  );

  const resendInvite = useCallback(
    (data: IResendEmailRequest) =>
      participantActions.resendInvite({
        data,
        onError,
        onSuccess: () => {
          showSnackbar({
            message: t(
              'my_purchase_details.success_messages.resend_invite_participant',
            ),
            type: 'success',
            duration: 3 * 1000,
          });
        },
      }),
    [onError, showSnackbar, t],
  );

  const [
    modalAfterRegisteringParticipant,
    setModalAfterRegisteringParticipant,
  ] = useState(false);

  return (
    <ParticipantContext.Provider
      value={{
        participants,
        loading,
        getParticipants,
        createParticipant,
        updateParticipant,
        resendInvite,
        modalAfterRegisteringParticipant,
        setModalAfterRegisteringParticipant,
      }}
    >
      {children}
    </ParticipantContext.Provider>
  );
};

export const useParticipant = (): IParticipantContext => {
  const participants = useContextSelector(
    ParticipantContext,
    (ctx: any) => ctx.participants,
  );
  const loading = useContextSelector(
    ParticipantContext,
    (ctx: any) => ctx.loading,
  );
  const resendInvite = useContextSelector(
    ParticipantContext,
    (ctx: any) => ctx.resendInvite,
  );
  const createParticipant = useContextSelector(
    ParticipantContext,
    (ctx: any) => ctx.createParticipant,
  );
  const updateParticipant = useContextSelector(
    ParticipantContext,
    (ctx: any) => ctx.updateParticipant,
  );
  const getParticipants = useContextSelector(
    ParticipantContext,
    (ctx: any) => ctx.getParticipants,
  );

  const setModalAfterRegisteringParticipant = useContextSelector(
    ParticipantContext,
    (ctx: any) => ctx.setModalAfterRegisteringParticipant,
  );

  const modalAfterRegisteringParticipant = useContextSelector(
    ParticipantContext,
    (ctx: any) => ctx.modalAfterRegisteringParticipant,
  );
  return {
    participants,
    loading,
    getParticipants,
    createParticipant,
    updateParticipant,
    resendInvite,
    modalAfterRegisteringParticipant,
    setModalAfterRegisteringParticipant,
  };
};
