import { IStudentCourseLesson } from '@utils/interfaces/studentCourseLesson';
import { useCourse } from '@hooks/course';
import { useUser } from '@hooks/user';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { IFormatedLessonsLive } from '@components/core/InfoStatus';
import dayjs from 'dayjs';

interface IPartnerCertificateContextData {
  quizTotal(): string;
  quizLessons: IStudentCourseLesson[];
  percentageLiveLesson: number;
  currentLiveZoom: any;
  liveZoomLessons: IFormatedLessonsLive[];
  loading: boolean;
}

const PartnerCertificateContext = createContext<IPartnerCertificateContextData>(
  {} as IPartnerCertificateContextData,
);

interface Props {
  children?: React.ReactNode;
}

const PartnerCertificateProvider: React.FC<Props> = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const { getAllLessonsQuiz, course, getAllLessonTypeLiveZoom, allLessons } =
    useCourse();
  const { user } = useUser();
  const [quizLessons, setQuizLessons] = useState<IStudentCourseLesson[]>([]);
  const [liveZoomLessons, setLiveZoomLessons] = useState<
    IFormatedLessonsLive[]
  >([]);
  const [countPresence, setCountPresence] = useState(0);
  const [totalZoomClassesCourse, setTotalZoomClassesCourse] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [presenceInLivesAverage, setPresenceInLivesAverage] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hasPresenceInLivesApproved, setHasPresenceInLivesApproved] =
    useState(false);
  const [currentLiveZoom, setCurrentLiveZoom] = useState(null);

  const getQuizLesson = useCallback(async () => {
    setLoading(true);

    const currentQuizLessons = await getAllLessonsQuiz({
      courseId: course?.id,
      studentId: user?._id,
      courseSlug: course?.slug,
    });
    setQuizLessons(currentQuizLessons);
    setLoading(false);
  }, [course?.id, course?.slug, getAllLessonsQuiz, user?._id]);

  useEffect(() => {
    if (user?._id && course?.id) {
      getQuizLesson();
    }
  }, [course?.id, getQuizLesson, user?._id]);

  const quizTotal = (): string => {
    let sums = 0;
    const quizLessonCompleted = quizLessons.filter(
      quizLesson => quizLesson.quizScore !== undefined,
    );
    quizLessonCompleted.map(quizLesson => {
      sums += quizLesson?.quizScore;
      return sums;
    });
    const totalQuiz = sums / (quizLessonCompleted.length * 10);
    if (!totalQuiz) {
      return '-';
    }
    if (Number.isInteger(totalQuiz)) {
      return totalQuiz.toString();
    }
    return totalQuiz.toFixed(1).toString();
  };

  const getPresenceLesson = async () => {
    setLoading(true);

    const currentLiveZoomLessons = await getAllLessonTypeLiveZoom({
      courseId: course.id,
      studentId: user._id,
    });
    const lessonsTypeZoom = allLessons.filter(
      lesson => lesson.lesson.type === 'liveZoom',
    );
    const formatedLiveZoom: IFormatedLessonsLive[] = [];

    lessonsTypeZoom.forEach(lesson => {
      const registredLesson = currentLiveZoomLessons.find(
        live => live.courseLesson.id === lesson.id,
      );
      const classEndDate = dayjs(lesson.liveInfos.when).add(
        lesson.liveInfos.duration,
        'minutes',
      );
      const classFinished = dayjs(classEndDate)
        .add(3, 'hour')
        .add(15, 'minute')
        .isBefore(dayjs());
      if (registredLesson) {
        formatedLiveZoom.push({
          title: registredLesson.courseLesson.liveInfos.title,
          presence: registredLesson.live.presenceInPerCent,
          hasApproved: registredLesson.hasApproved,
          classFinished,
          courseLessonId: registredLesson.courseLesson.id,
          when: registredLesson.courseLesson.liveInfos.when,
          status: 'waiting',
          countPresence:
            registredLesson.courseLesson.liveInfos.countPresence || true,
        });
      } else {
        formatedLiveZoom.push({
          title: lesson.liveInfos.title,
          presence: 0,
          hasApproved: false,
          classFinished,
          courseLessonId: lesson.id,
          when: lesson?.liveInfos?.when,
          status: 'waiting',
          countPresence: lesson?.liveInfos?.countPresence,
        });
      }
    });

    setLiveZoomLessons(
      formatedLiveZoom.filter(liveZoom => liveZoom.countPresence),
    );

    const liveZoomToday = formatedLiveZoom.find(liveZoom =>
      dayjs(liveZoom.when).isSame(dayjs(), 'day'),
    );

    setCurrentLiveZoom(liveZoomToday);

    const totalPresence = formatedLiveZoom.reduce((acc, current) => {
      return acc + current.presence;
    }, 0);
    if (formatedLiveZoom?.length > 0) {
      setPresenceInLivesAverage(
        parseFloat((totalPresence / formatedLiveZoom.length).toFixed(2)),
      );
      setTotalZoomClassesCourse(
        formatedLiveZoom.filter(liveZoom => liveZoom.countPresence).length,
      );
      setCountPresence(
        formatedLiveZoom.filter(liveZoom => liveZoom.hasApproved).length,
      );
      setHasPresenceInLivesApproved(
        !(
          formatedLiveZoom.find(liveZoom => !liveZoom.hasApproved) !== undefined
        ),
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    if (user?._id && course?.id) {
      getPresenceLesson();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course?.id, user?._id]);

  const percentageLiveLesson = Math.round(
    (countPresence / totalZoomClassesCourse) * 100,
  );

  return (
    <PartnerCertificateContext.Provider
      value={{
        quizTotal,
        quizLessons,
        percentageLiveLesson,
        currentLiveZoom,
        liveZoomLessons,
        loading,
      }}
    >
      {children}
    </PartnerCertificateContext.Provider>
  );
};

function usePartnerCertificate(): IPartnerCertificateContextData {
  const context = useContext(PartnerCertificateContext);

  if (!context) {
    throw new Error(
      'PartnerCertificate must be used within a PartnerCertificateProvider',
    );
  }

  return context;
}

export { usePartnerCertificate, PartnerCertificateProvider };
