import axios from 'axios';
import {
  ICreateUpdateUserUseCase,
  IGetUserUseCase,
} from '@domain/moonshot/use-cases';
import cookies from '@utils/cookies';
import { IMoonshotProvider } from '@domain/moonshot/UserMoonshotProvider';
import ResultsNotFound from '@domain/moonshot/errors/ResultsNotFound';
import MoonshotBaseApi from './MoonshotBaseApi';

export default class MoonshotProvider
  extends MoonshotBaseApi
  implements IMoonshotProvider
{
  public authUser = cookies.get('authStartseToken');

  public config = {
    headers: {
      'x-auth-token': this.authUser,
      'Content-Type': 'application/json',
    },
  };

  public async getUser(
    params: IGetUserUseCase.GetUserParams,
  ): Promise<IGetUserUseCase.GetUserResponse> {
    const query = {
      setUser: variables => {
        variables.email = '{{email}}';
        return variables;
      },
      data: `
        query GetUserByEmail($email: String!) {
          getUserByEmail(email: $email) {
            _id
            email
            bio
            firstName
            lastName
            nickname
            profileImageURL
            country
            state
            city
            jobtitle
            headline
            dateOfBirth
            phone
            linkedin
            gender
            interests
            segment
            challenge
            hasCompletedProfile
            department
            hideUserData
            pains
            lookingForRelocation
            onboardingStep
            onboardingOption
            document
            completedOnboarding
            courtesyEvent
            company {
              name
              revenues
              employees
              market
            }
            educationInterest {
              wantToLearn
              whatCanTeach
            }
          }
        }
      `,
    };

    try {
      const { data } = await axios.post(
        `${this.baseUrl}`,
        {
          query: query.data,
          variables: JSON.stringify(query.setUser(params || {})),
        },
        this.config,
      );

      return data?.getUserByEmail;
    } catch {
      throw new ResultsNotFound();
    }
  }

  public async createUpdateUser(
    params: ICreateUpdateUserUseCase.CreateUpdateUserParams,
  ): Promise<ICreateUpdateUserUseCase.CreateUpdateUserResponse> {
    const query = {
      setUser: variables => {
        variables.email = '{{email}}';
        return variables;
      },
      data: `
        mutation CreateUser($input: createUserInput!) {
          createUser(input: $input) {
            _id
            email
            bio
            firstName
            lastName
            nickname
            profileImageURL
            country
            state
            city
            jobtitle
            headline
            dateOfBirth
            phone
            linkedin
            gender
            interests
            segment
            challenge
            hasCompletedProfile
            department
            hideUserData
            pains
            lookingForRelocation
            onboardingOption
            completedOnboarding
            document
            onboardingStep
            courtesyEvent
            company {
              name
              revenues
              employees
              market
            }
            educationInterest {
              wantToLearn
              whatCanTeach
            }
          }
        }
      `,
    };

    try {
      const { data } = await axios.post(
        `${this.baseUrl}`,
        {
          query: query.data,
          variables: JSON.stringify(query.setUser(params || {})),
        },
        this.config,
      );
      return data?.createUser;
    } catch {
      throw new ResultsNotFound();
    }
  }
}
