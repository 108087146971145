import { createContext, useContext, useState } from 'react';

interface IRefererContextData {
  refererTo: string;
  setRefererTo(refererTo: string): void;
}

export const RefererContext = createContext<IRefererContextData>(
  {} as IRefererContextData,
);
interface Props {
  children?: React.ReactNode;
}

const RefererProvider: React.FC<Props> = ({ children }) => {
  const [refererTo, setRefererTo] = useState<string>('');

  return (
    <RefererContext.Provider
      value={{
        refererTo,
        setRefererTo,
      }}
    >
      {children}
    </RefererContext.Provider>
  );
};

function useReferer(): IRefererContextData {
  const context = useContext(RefererContext);

  if (!context) {
    throw new Error('Referer must be used within a RefererProvider');
  }

  return context;
}

export { useReferer, RefererProvider };
