import { ICourseStatus } from '@utils/interfaces/course';
import api from '../api';

export const getStatusCourse = async (
  values: ICourseStatus,
): Promise<ICourseStatus> => {
  const { email, productEditionId } = values;
  const statusCourse = await api.get<ICourseStatus>(
    `status?email=${email}&productEditionId=${productEditionId}`,
  );

  return statusCourse.data;
};
