/* eslint-disable react-hooks/exhaustive-deps */
import { Analytics } from 'analytics';
import segmentPlugin from '@analytics/segment';
import {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import { pageViewedName, pathSegment, SEGMENT_APP_TYPE } from '@utils/segment';

export interface TrackingSegmentContextData {
  trackSegment(eventName: string, props: object): void;
  pageSegment(props: object): void;
  identifySegment(userId?: string, props?: object): void;
  groupSegment(groupId?: string, props?: object): void;
}

export const TrackingContext = createContext<TrackingSegmentContextData>(
  {} as TrackingSegmentContextData,
);

const TrackingSegmentProvider: React.FC = ({ children }) => {
  const analytics = Analytics({
    app: SEGMENT_APP_TYPE.LMS,
    debug: true,
    plugins: [
      segmentPlugin({
        writeKey: `${process.env.NEXT_PUBLIC_SEGMENT_KEY}`,
      }),
    ],
  });

  const trackSegment = useCallback((eventName, props = {}) => {
    analytics.track(eventName, props);
  }, []);

  const pageSegment = useCallback((props = {}) => {
    analytics.page(props);
  }, []);

  const identifySegment = useCallback((userId = '', props = {}) => {
    analytics.identify(userId, props);
  }, []);

  const groupSegment = useCallback(
    (groupId = '', props = {}) => {
      if (analytics && analytics.plugins?.segment) {
        analytics.plugins?.segment.group(groupId, props);
      }
    },
    [analytics],
  );

  return (
    <TrackingContext.Provider
      value={{ trackSegment, identifySegment, groupSegment, pageSegment }}
    >
      {children}
    </TrackingContext.Provider>
  );
};

export const PageViewedTrack: React.FC = () => {
  const router = useRouter();
  const { pageSegment } = useTrackingSegment();
  const [link, setLink] = useState('');
  const loadPageTrack = () => {
    const path = router.asPath;
    let referer = '';

    if (!link && document.referrer) {
      referer = document.referrer;
    } else {
      referer = link;
    }

    if (path) {
      pageSegment({
        ContentCategory: 'LMS',
        ContentTag: pathSegment(path),
        ContentType: 'Curso',
        referrer: referer,
        name: pageViewedName(path),
      });
    }
  };

  useEffect(() => {
    router.events.on('routeChangeComplete', loadPageTrack);

    if (!document.referrer) {
      setLink(window.location.href);
    }

    return () => {
      router.events.off('routeChangeComplete', loadPageTrack);
    };
  }, [router]);

  return <></>;
};

function useTrackingSegment(): TrackingSegmentContextData {
  const context = useContext(TrackingContext);

  if (!context) {
    throw new Error('Tracking must be used within a TrackingProvider');
  }

  return context;
}

export { useTrackingSegment, TrackingSegmentProvider };
