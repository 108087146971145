import TextField from '@material-ui/core/TextField';
import { styled } from '@material-ui/core';

export const TextFieldUI: any = styled(TextField)(({ theme }) => ({
  width: '100%',
  margin: '0px',

  '& input': {
    fontSize: '18px !important',
    fontfamily: 'Barlow',
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px !important',
    },
  },
  '& .MuiInputBase-root': {
    borderRadius: '12px',
  },

  '& .MuiFormLabel-root': {
    fontSize: '18px !important',
    fontfamily: 'Barlow',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px !important',
    },
  },

  '& label.Mui-focused': {
    color: '#0080ED',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#0080ED',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#D3D3D3',
    },
    '&:hover fieldset': {
      borderColor: '#0080ED',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#0080ED',
    },
  },
}));
