import { createContext, useCallback, useContext } from 'react';
import ReactGA from 'react-ga';

interface GaContextData {
  sendEvent(
    eventName: string,
    action: string,
    label: string,
    nonInteraction?: boolean,
  ): void;
}

const GaContext = createContext<GaContextData>(null);

const GaProvider: React.FC = ({ children }) => {
  ReactGA.initialize(process.env.NEXT_PUBLIC_GA_UA);
  const sendEvent = useCallback(
    (eventName, action, label, nonInteraction = false) => {
      try {
        ReactGA.event({
          category: eventName,
          action,
          label,
          nonInteraction,
        });
      } catch (error) {
        console.error(error);
      }
    },
    [],
  );

  return (
    <GaContext.Provider value={{ sendEvent }}>{children}</GaContext.Provider>
  );
};

function useGa(): GaContextData {
  const context = useContext(GaContext);

  if (!context) {
    throw new Error('GA must be used within a GaProvider');
  }

  return context;
}

export { useGa, GaProvider };
