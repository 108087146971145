import { participantsController } from '@services/checkout';
import {
  ICreateParticipantsRequest,
  IGetParticipantsRequest,
  IResendEmailRequest,
  IUpdateParticipantsRequest,
} from '@utils/interfaces/participant';
import { IParticipantFunctions, IParticipantState } from './types';

export default class ParticipantsActions {
  public async index({
    data,
    onError,
    onLoad,
    onSuccess,
  }: Pick<
    IParticipantFunctions<
      IGetParticipantsRequest,
      Pick<IParticipantState, 'participants'>
    >,
    'data' | 'onError' | 'onLoad' | 'onSuccess'
  >): Promise<void> {
    try {
      onLoad(true);
      const participants = await participantsController.index(data);
      onSuccess({
        participants,
      });
    } catch (err) {
      onError(err);
    } finally {
      onLoad(false);
    }
  }

  public async update({
    data,
    onError,
    onLoad,
    onSuccess,
    state,
  }: IParticipantFunctions<
    IUpdateParticipantsRequest,
    Pick<IParticipantState, 'participants'>,
    Pick<IParticipantState, 'participants'>
  >): Promise<void> {
    try {
      onLoad(true);
      const { participants } = state;

      await participantsController.update(data);

      const index = participants.findIndex(
        participant => participant.id === data.participants[0].id,
      );
      participants[index] = data.participants[0];
      onSuccess({
        participants: [...participants],
      });
    } catch (err) {
      onError(err);
    } finally {
      onLoad(false);
    }
  }

  public async create({
    data,
    onError,
    onLoad,
    onSuccess,
  }: Pick<
    IParticipantFunctions<
      ICreateParticipantsRequest,
      Pick<IParticipantState, 'participants'>
    >,
    'data' | 'onError' | 'onLoad' | 'onSuccess'
  >): Promise<void> {
    try {
      onLoad(true);
      const participants = await participantsController.create(data);
      onSuccess({
        participants,
      });
    } catch (err) {
      onError(err);
    } finally {
      onLoad(false);
    }
  }

  public async resendInvite({
    data,
    onError,
    onSuccess,
  }: Pick<
    IParticipantFunctions<IResendEmailRequest, null, null>,
    'onError' | 'data' | 'onSuccess'
  >): Promise<void> {
    try {
      await participantsController.resendEmail(data);
      onSuccess(null);
    } catch (err) {
      onError(err);
    }
  }
}
