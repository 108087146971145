import { useSnackbar } from '@hooks/snackbar';
import { getMessageByError } from '@utils/format/getMessageByError';
import { ICustomerUpdate } from '@utils/interfaces/customer';
import {
  IPurchaseDetailsRequest,
  IPurchasesListRequest,
} from '@utils/interfaces/purchase';
import { useTranslation } from 'next-i18next';
import { useCallback, useState } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import PurchaseActions from './actions';

import { IPurchaseContext, IPurchaseState } from './types';

const PurchaseContext: any = createContext<IPurchaseContext>(
  {} as IPurchaseContext,
);

const purchaseActions = new PurchaseActions();

const initialState: IPurchaseState = {
  hasMore: false,
  purchase: null,
  purchaseIndexSelected: null,
  purchasesList: {
    count: 0,
    data: null,
  },
};

export const PurchaseProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState<IPurchaseState>(initialState);

  const { purchase, purchasesList, hasMore, purchaseIndexSelected } = state;

  const onLoad = useCallback(load => setLoading(load), []);

  const onError = useCallback(
    err => {
      showSnackbar({
        message: t(`errors.${getMessageByError(err)}`),
        type: 'error',
        duration: 5 * 1000,
      });
    },
    [showSnackbar, t],
  );

  const getPurchasesList = useCallback(
    (data: IPurchasesListRequest) =>
      purchaseActions.index({
        data,
        state: {
          purchasesList,
        },
        onLoad,
        onError,
        onSuccess: newState =>
          setState(prevState => ({ ...prevState, ...newState })),
      }),
    [onError, onLoad, purchasesList],
  );

  const getPurchaseDetails = useCallback(
    (data: IPurchaseDetailsRequest) =>
      purchaseActions.show({
        data,
        onError,
        onLoad,
        onSuccess: newState =>
          setState(prevState => ({ ...prevState, ...newState })),
      }),
    [onError, onLoad],
  );

  const updateCustomerAddress = useCallback(
    (data: ICustomerUpdate, callToSuccess: () => void) =>
      purchaseActions.updateAddress({
        data,
        onError,
        onLoad,
        onSuccess: newState => {
          showSnackbar({
            message: t('my_purchase_details.success_messages.update_address'),
            type: 'success',
            duration: 3 * 1000,
          });
          setState(prevState => ({ ...prevState, ...newState }));
          callToSuccess();
        },
        state: {
          purchase,
        },
      }),
    [onError, onLoad, purchase, showSnackbar, t],
  );

  const setPurchaseIndexSelected = useCallback(
    (index: number) =>
      setState(prevState => ({
        ...prevState,
        purchaseIndexSelected: index,
        purchase: null,
      })),
    [],
  );

  return (
    <PurchaseContext.Provider
      value={{
        setPurchaseIndexSelected,
        purchaseIndexSelected,
        hasMore,
        loading,
        purchase,
        getPurchasesList,
        purchasesList,
        getPurchaseDetails,
        updateCustomerAddress,
      }}
    >
      {children}
    </PurchaseContext.Provider>
  );
};

export const usePurchase = (): IPurchaseContext => {
  const purchase = useContextSelector(
    PurchaseContext,
    (ctx: any) => ctx.purchase,
  );
  const loading = useContextSelector(
    PurchaseContext,
    (ctx: any) => ctx.loading,
  );
  const hasMore = useContextSelector(
    PurchaseContext,
    (ctx: any) => ctx.hasMore,
  );
  const setPurchaseIndexSelected = useContextSelector(
    PurchaseContext,
    (ctx: any) => ctx.setPurchaseIndexSelected,
  );
  const purchaseIndexSelected = useContextSelector(
    PurchaseContext,
    (ctx: any) => ctx.purchaseIndexSelected,
  );
  const updateCustomerAddress = useContextSelector(
    PurchaseContext,
    (ctx: any) => ctx.updateCustomerAddress,
  );
  const getPurchaseDetails = useContextSelector(
    PurchaseContext,
    (ctx: any) => ctx.getPurchaseDetails,
  );
  const purchasesList = useContextSelector(
    PurchaseContext,
    (ctx: any) => ctx.purchasesList,
  );
  const getPurchasesList = useContextSelector(
    PurchaseContext,
    (ctx: any) => ctx.getPurchasesList,
  );

  return {
    setPurchaseIndexSelected,
    purchaseIndexSelected,
    hasMore,
    loading,
    purchase,
    purchasesList,
    getPurchasesList,
    getPurchaseDetails,
    updateCustomerAddress,
  };
};
