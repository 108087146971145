import {
  IListPurchasesResponse,
  IPurchaseDetails,
  IPurchaseDetailsRequest,
  IPurchasesListRequest,
  IRegeneratePixQRCode,
} from '@utils/interfaces/purchase';
import api from '../api';

export const index = async (
  params: IPurchasesListRequest,
): Promise<IListPurchasesResponse> => {
  const response = await api.get<IListPurchasesResponse>('/purchases', {
    params,
  });
  return response.data;
};

export const show = async (
  params: IPurchaseDetailsRequest,
): Promise<IPurchaseDetails> => {
  const response = await api.get<IPurchaseDetails>(
    `/purchases/${params.guid}`,
    {
      headers: { email: Buffer.from(params.email, 'utf8').toString('base64') },
    },
  );

  return response.data;
};

export const regenerateQrCode = async (
  guid: string,
): Promise<IRegeneratePixQRCode> => {
  const response = await api.put<IRegeneratePixQRCode>(
    `/payments/pix/${guid}/regenerate`,
  );

  return response.data;
};
