export enum TALENTS_BANK {
  ID_COURSE = '5f352c9d68910a071d4975a4',
  ID_TECH_ACADEMY = '62f26c112a37770ea828d9ef',
}

export enum TALENTS_AGE {
  MINOR_TWENTY = 'talents.minor_twenty',
  BETWEEN_TWENTY_THIRTY = 'talents.between_twenty_thirty',
  BETWEEN_THIRTY_FORTY = 'talents.between_thirty_forty',
  MAJOR_FORTY = 'talents.major_forty',
}

export enum TALENTS_GENDER {
  MALE = 'talents.male',
  FEMALE = 'talents.female',
  OTHERS = 'talents.other',
}

export enum TALENTS_WORK_MODEL {
  HOME = 'talents.home',
  PRESENCIAL = 'talents.presential',
  HYBRID = 'talents.hybrid',
}

export enum TALENTS_SALARY_RANGE {
  AT25 = '2k - 2.5k',
  AT3 = '2.5k - 3k',
  AT35 = '3k - 3.5k',
  AT4 = '3.5k - 4k',
  UP4 = 'Acima de 4k',
  N_AT25 = 'talents.primary_salary',
  N_AT3 = 'talents.secondary_salary',
  N_AT35 = 'talents.third_salary',
  N_AT4 = 'talents.fourth_salary',
  N_UP4 = 'talents.fifth_salary',
  N_MATCH = 'talents.match',
}
export const state = [
  { label: 'Todos os Estados', value: 'all' },
  { label: 'Acre', value: 'AC' },
  { label: 'Alagoas', value: 'AL' },
  { label: 'Amapá', value: 'AP' },
  { label: 'Amazonas', value: 'AM' },
  { label: 'Bahia', value: 'BA' },
  { label: 'Ceará', value: 'CE' },
  { label: 'Espírito Santo', value: 'ES' },
  { label: 'Goiás', value: 'GO' },
  { label: 'Maranhão', value: 'MA' },
  { label: 'Mato Grosso', value: 'MT' },
  { label: 'Mato Grosso do Sul', value: 'MS' },
  { label: 'Minas Gerais', value: 'MG' },
  { label: 'Pará', value: 'PA' },
  { label: 'Paraíba', value: 'PB' },
  { label: 'Paraná', value: 'PR' },
  { label: 'Pernambuco', value: 'PE' },
  { label: 'Piauí', value: 'PI' },
  { label: 'Rio de Janeiro', value: 'RJ' },
  { label: 'Rio Grande do Norte', value: 'RN' },
  { label: 'Rio Grande do Sul', value: 'RS' },
  { label: 'Rondônia', value: 'RO' },
  { label: 'Roraima', value: 'RR' },
  { label: 'Santa Catarina', value: 'SC' },
  { label: 'São Paulo', value: 'SP' },
  { label: 'Sergipe', value: 'SE' },
  { label: 'Tocantins', value: 'TO' },
  { label: 'Distrito Federal', value: 'DF' },
];
