import { ICourseAttendance } from '@utils/interfaces/course';
import api from '../api';

export const getCourses = async (
  email: string,
): Promise<ICourseAttendance[]> => {
  const coursesToAttendanceResponse = await api.get<ICourseAttendance[]>(
    `/unconfirmedattendance?email=${email}`,
  );

  return coursesToAttendanceResponse.data;
};
