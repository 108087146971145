import CloseIcon from './CloseIcon';

import { Container } from './styles';

interface CloseButtonProps {
  onClick?: () => void;
}

const CloseButton: React.FC<CloseButtonProps> = ({ onClick }) => {
  return (
    <Container onClick={onClick} className="right-btn">
      <CloseIcon />
    </Container>
  );
};

export default CloseButton;
