import { useAuth } from '@hooks/auth';
import { useUser } from '@hooks/user';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const LoginHome: any = dynamic(
  () => import('@startse/signin-component').then(module => module.LoginHome),
  { ssr: false },
);
const Login: React.FC = () => {
  const { showModalLogin, setShowModalLogin, validateLogged, authChecked } =
    useAuth();
  const { user } = useUser();
  const [openDialog, setOpenDialog] = useState(true);
  const [queryParams, setQueryParams] = useState(null);
  const { locale, query, isReady } = useRouter();

  useEffect(() => {
    if (
      queryParams?.userId &&
      queryParams?.resetPasswordToken &&
      !showModalLogin
    ) {
      setShowModalLogin(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  useEffect(() => {
    if (openDialog && authChecked) {
      setShowModalLogin(user?._id === undefined);
      setOpenDialog(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authChecked]);

  const handleCloseDialog = async (validateLogin = true) => {
    setShowModalLogin(false);

    if (validateLogin) {
      await validateLogged();
    }
  };

  if (!queryParams && isReady) {
    setQueryParams(query);
  }

  if (user && user._id) return null;

  return (
    <LoginHome
      openDialog={showModalLogin}
      onClose={handleCloseDialog}
      language={locale}
      userId={queryParams?.userId ? String(queryParams?.userId) : null}
      resetPasswordToken={
        queryParams?.resetPasswordToken
          ? String(queryParams?.resetPasswordToken)
          : null
      }
      handleCloseDialog={handleCloseDialog}
      messageTypes="home"
    />
  );
};

export default Login;
