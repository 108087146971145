import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: 1.0,
  org: 'startse',
  project: 'lms-panel',
  ignoreErrors:[
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
    'Non-Error promise rejection captured with keys: message',
    'Request failed with status code 404',
    'Network Error',
    'UnhandledRejection: Non-Error promise rejection captured with keys: message',
    'Error: [object XMLHttpRequest]',
   ]
});
