import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_APP}/api/checkout`,
});

api.interceptors.request.use(config => {
  config.headers = {
    ...config.headers,
    pathTo: config.url,
  };
  config.url = '';

  return config;
});

export default api;
