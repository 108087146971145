import { makeStyles, styled, Typography } from '@material-ui/core';

export const useStyles = makeStyles({
  bottom: {
    color: '#000000',
    zIndex: 2,
  },
  top: {
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
    zIndex: 2,
  },
  circle: {
    strokeLinecap: 'round',
  },
});

export const Label: any = styled(Typography)(({ theme }) => ({
  fontFamily: 'Barlow',
  fontSize: 21,
  color: '#000000',
  fontWeight: 600,
  lineHeight: '34px',
  '& span': {
    textAlign: 'start',
    fontSize: 16,
    lineHeight: '22px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 18,
    lineHeight: 'px',
    '& span': {
      fontSize: 14,
      lineHeight: '21px',
    },
  },
}));
