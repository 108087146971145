import { ReactNode } from 'react';
import { Link } from '@components/ui';
import { Header, Title, Subtitle, TitleGroup } from './styles';

export interface IHeaderForDrawerPages {
  title: string;
  subtitle?: string | ReactNode;
  linkTo?: {
    label: string;
    href: string;
  };
}

const HeaderForDrawerPages: React.FC<IHeaderForDrawerPages> = ({
  title,
  subtitle,
  linkTo,
}) => {
  return (
    <Header>
      <TitleGroup>
        <Title>{title}</Title>
        {linkTo && (
          <Link noDecoration href={linkTo.href}>
            {linkTo.label}
          </Link>
        )}
      </TitleGroup>
      <Subtitle>{subtitle}</Subtitle>
    </Header>
  );
};

export default HeaderForDrawerPages;
