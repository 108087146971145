/* eslint-disable no-irregular-whitespace */
import { Hidden, Grid } from '@material-ui/core';

import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import StartseLogo from '../../../assets/svg/startse-logo.svg';

import {
  Container,
  ContainerLogo,
  Info,
  Bold,
  FooterIcons,
  Mt,
  Fs12,
  Fs11,
  Contacts,
  BoxAll,
} from './styles';

const Footer: React.FC = () => {
  const HiddenMaterial: any = Hidden;
  return (
    <BoxAll>
      <Container>
        <HiddenMaterial only={['xs']}>
          <Grid container>
            <Grid
              container
              item
              xs={12}
              style={{ margin: '20px 0px 40px 0px' }}
            >
              <ContainerLogo>
                <StartseLogo />
              </ContainerLogo>
            </Grid>
            <Grid container item xs={3}>
              <p>
                <Bold>Siga a StartSe nas Redes Sociais:</Bold>
              </p>
              <FooterIcons style={{ marginBottom: '130px' }}>
                <a
                  href="https://www.instagram.com/startseoficial"
                  rel="noreferrer"
                  target="_blank"
                >
                  <InstagramIcon />
                </a>
                <a
                  href="https://www.facebook.com/startseoficial"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FacebookIcon />
                </a>
                <a
                  href="https://www.youtube.com/startseoficial"
                  target="_blank"
                  rel="noreferrer"
                >
                  <YouTubeIcon />
                </a>
                <a
                  href="https://www.linkedin.com/company/startse"
                  target="_blank"
                  rel="noreferrer"
                >
                  <LinkedInIcon />
                </a>
                <a
                  href="https://twitter.com/startseoficial"
                  target="_blank"
                  rel="noreferrer"
                >
                  <TwitterIcon />
                </a>
              </FooterIcons>
            </Grid>
            <Grid container item xs={6}>
              <Contacts>
                <p>
                  <Bold>Contato (De segunda a sexta, das 10h às 18h)</Bold>
                </p>
                <p>
                  <Bold>(11) 96189-1624 </Bold>- Atendimento exclusivo
                  telefônico
                </p>
                <p>
                  <Bold>(11) 97244-8378 </Bold> - Atendimento exclusivo WhatsApp
                </p>
                <Mt>
                  <p>
                    <Bold>E-MAILS:</Bold> atendimento@startse.com |
                    dpo@startse.com
                  </p>
                </Mt>
              </Contacts>
              <Mt>
                <p>
                  <Bold>
                    É um veículo de comunicação e quer receber nossos releases
                    ou agendar uma entrevista conosco?
                  </Bold>
                </p>
                <p>assessoria@startse.com</p>
              </Mt>
            </Grid>
            <Grid container item xs={3}>
              <p>Todos os direitos reservados</p>
              <p>© startse.com</p>
              <p>São Paulo, Brasil</p>
              <p>Palo Alto, EUA</p>
              <p style={{ marginBottom: '80px' }}>Shanghai, China</p>
            </Grid>
            <Grid
              container
              item
              xs={12}
              style={{ borderTop: 'solid 3px #e6e6e6', marginTop: '20px' }}
            />
            <Grid container item xs={8}>
              <Mt>
                <Info>
                  <Mt>
                    <Fs12>
                      STARTSE TURISMO DE NEGOCIOS LTDA CPNJ: 32.291.526/0001-64
                      | STARTSE INFORMACOES E SISTEMAS S/A CNPJ:
                      24.554.736/0002-50
                    </Fs12>
                  </Mt>
                </Info>
              </Mt>
            </Grid>
            <Grid container item xs={4} style={{ marginTop: '10px' }}>
              <Mt>
                <Info style={{ float: 'left' }}>
                  <a
                    href="https://docs.startse.com/politica-de-privacidade"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Política de Privacidade e Termos
                  </a>
                  &nbsp; | &nbsp;
                  <a
                    href="https://docs.startse.com/politicas-gerais-de-cancelamento-e-remarcacao"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Políticas Gerais de Cancelamento e Remarcação
                  </a>
                </Info>
              </Mt>
            </Grid>
          </Grid>
        </HiddenMaterial>

        <HiddenMaterial smUp>
          <ContainerLogo>
            <StartseLogo />
          </ContainerLogo>
          <Grid>
            <FooterIcons>
              <a
                href="https://www.instagram.com/startseoficial"
                rel="noreferrer"
                target="_blank"
              >
                <InstagramIcon />
              </a>
              <a
                href="https://www.facebook.com/startseoficial"
                rel="noreferrer"
                target="_blank"
              >
                <FacebookIcon />
              </a>
              <a
                href="https://www.youtube.com/startseoficial"
                rel="noreferrer"
                target="_blank"
              >
                <YouTubeIcon />
              </a>
              <a
                href="https://www.linkedin.com/company/startse"
                rel="noreferrer"
                target="_blank"
              >
                <LinkedInIcon />
              </a>
              <a
                href="https://twitter.com/startseoficial"
                rel="noreferrer"
                target="_blank"
              >
                <TwitterIcon />
              </a>
            </FooterIcons>
          </Grid>
          <Grid style={{ fontSize: '11px' }}>
            <Fs11>
              <Contacts>
                <p>
                  <Bold>Contato (De segunda a sexta, das 10h às 18h)</Bold>
                </p>
                <p>
                  <Bold>(11) 96189-1624 </Bold>- Atendimento exclusivo
                  telefônico
                </p>
                <p>
                  <Bold>(11) 97244-8378 </Bold> - Atendimento exclusivo WhatsApp
                </p>
                <Mt>
                  <p>
                    <Bold>E-MAILS:</Bold> atendimento@startse.com |
                    dpo@startse.com
                  </p>
                </Mt>
              </Contacts>
              <Mt>
                <p>
                  <Bold>
                    É um veículo de comunicação e quer receber nossos releases
                    ou agendar uma entrevista conosco?
                  </Bold>
                </p>
                <p>assessoria@startse.com</p>
              </Mt>
              <Mt>
                <p>Todos os direitos reservados © startse.com</p>
                <p>São Paulo, Brasil, Palo Alto, EUA, Shanghai, China</p>
                <p>STARTSE TURISMO DE NEGOCIOS LTDA CPNJ:</p>
                <p>32.291.526/0001-64</p>
                <p>STARTSE INFORMACOES E SISTEMAS S/A CNPJ:</p>
                <p>24.554.736/0002-50</p>
              </Mt>
            </Fs11>
          </Grid>
          <Grid container item>
            <Mt>
              <p>
                <a
                  style={{ fontSize: '9px' }}
                  href="https://docs.startse.com/politica-de-privacidade"
                  rel="noreferrer"
                  target="_blank"
                >
                  Política de Privacidade e Termos
                </a>
                &nbsp; | &nbsp;
                <a
                  style={{ fontSize: '9px' }}
                  href="https://docs.startse.com/politicas-gerais-de-cancelamento-e-remarcacao"
                  rel="noreferrer"
                  target="_blank"
                >
                  Políticas Gerais de Cancelamento e Remarcação
                </a>
              </p>
            </Mt>
          </Grid>
        </HiddenMaterial>
      </Container>
    </BoxAll>
  );
};

export default Footer;
