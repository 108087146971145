import { useTracking } from '@hooks/tracking';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const GlobalTrack: React.FC = () => {
  const router = useRouter();
  const { track } = useTracking();

  useEffect(() => {
    const loadPageTrack = () => {
      if (track) {
        track('Viewed page', {
          page_url: window.location.href,
        });
      }
    };

    router.events.on('routeChangeComplete', loadPageTrack);

    return () => {
      router.events.off('routeChangeComplete', loadPageTrack);
    };
  }, [router, track]);

  return <></>;
};

export default GlobalTrack;
