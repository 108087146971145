import ResultsNotFound from '@domain/moonshot/errors/ResultsNotFound';
import { IMoonshotProvider } from '@domain/moonshot/UserMoonshotProvider';
import { ICreateUpdateUserUseCase } from './ICreateUpdateUserUseCase';

export default class CreateUpdateUserUseCase
  implements ICreateUpdateUserUseCase
{
  // eslint-disable-next-line no-useless-constructor
  constructor(private moonshotProvider: IMoonshotProvider) {}

  async execute(
    params: ICreateUpdateUserUseCase.CreateUpdateUserParams,
  ): Promise<ICreateUpdateUserUseCase.CreateUpdateUserResponse> {
    const createOrUpdateUser = await this.moonshotProvider.createUpdateUser(
      params,
    );

    if (!createOrUpdateUser) {
      throw new ResultsNotFound();
    }

    return createOrUpdateUser;
  }
}
